
import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

export interface FormationInterface {
  id: number;
  name: string;
  description: string;
  contact: string;
  prerequisite: PrerequisiteInterface[];
  competences: CompetenceInterface[];
  avatar: string;
  discipline: string;
}

export interface PageFormationInterface {
  totalCount: number;
  page: number;
  limit: number;
  data: any[];
}
export interface CompetenceInterface {
  id : number;
  name: string;
  profile : string;
  category: string
  point : number;
}
export interface PrerequisiteInterface {
  id : number;
  name: string;
  formation : FormationInterface[];
  question : string
}


export interface UserInterface {
  email : string;
  password : string;
  role : string;
}

export interface QuestionInterface {
  id: number;
  name: string;
  profile : string;
  prerequisite : any
  competences : any
}
@Injectable({
  providedIn: 'root'
})


export class ApiService {
  url = "https://elanback.herokuapp.com";

  constructor( private http: HttpClient) { }

  // Récupére toute les formation la ou le nom ou la description ressemble a la donnée entrée

  getByText(text) {
    return this.http.get(this.url+ '/formation/text/' + text);
  }

  // Récupére toute les formation la ou le nom ou la description ressemble a la donnée entrée avec la pagination
  private questionsList: any;

  getByTextLimit(text, page) {
    return this.http.get(this.url + '/formation/text/' + text + '?page=' + page + '&limit=8');
  }

  getAll(){
    return this.http.get<FormationInterface[]>(this.url + '/formation');
  }

  getCompetenceByRiasec(comp1 :string, comp2 : string){
    return this.http.post<CompetenceInterface[]>(this.url + '/competence/maxi', {comp1 : comp1 ,comp2 : comp2})
  }
  getAllLimit(page) {
    console.log(this.url)
    return this.http.get<PageFormationInterface[]>(this.url + '/formation/page?page=' + page + '&limit=8');
  }

  getFormationById(id: string) {
    return this.http.get<FormationInterface>(this.url + '/formation/' +id);
  }
  getCompetence(){
    return this.http.get<CompetenceInterface[]>(this.url +'/competence');
  }
  getJob(){
    return this.http.get<CompetenceInterface[]>(this.url +'/job');
  }
  getCompetenceById(id){
    return this.http.get<CompetenceInterface[]>(this.url + '/competence/id/' + id);
  }

  getPrerequisiteById(id){
    return this.http.get<any[]>(this.url + '/prerequisite/id/' + id);
  }



  //Recupere 2 question random par categorie riasec
  getQuestionRiasec() {
    return this.http.get<QuestionInterface[]>(this.url + '/questionRIASEC/listQuestions');
  }


  getQuestionCompetence() {
    return this.http.get<QuestionInterface[]>(this.url + '/question/competence');
  }

  getQuestionPrerequis() {
    return this.http.get<QuestionInterface[]>(this.url + '/question/prerequis');
  }

  getQuestionPrerequisById(id) {
    return this.http.get<QuestionInterface[]>(this.url + '/question/id/'+ id);
  }

  getUser(username: string, password: string) {
    return this.http.get(this.url +'/user/' + username + '/' + password);
  }

  getCompetenceBySearchText(search: string) {
    return this.http.get<CompetenceInterface[]>(this.url + '/competence/' + search);
  }


  SortByDiscipline(text , page) {
    return this.http.get(this.url + '/formation/discipline/' + text + '?page=' + page + '&limit=8');
  }


  postCompetence(nameC: string, typeC: string, profil: string) {
    return this.http.post(this.url + '/competence', {
      name: nameC,
      category: typeC,
      profile: profil.toString()
    });
  }

  postCom(nameL: string,) {
    return this.http.post(this.url + '/logs', {
      Logs: nameL.toString(),
    });
  }

  postFormation(nameF: string, descriptionF: string , jobF: string, contactF: string ,prerequiseF: string,competenceF:CompetenceInterface[]) {
    console.log(nameF,descriptionF,jobF,contactF,prerequiseF,competenceF)
    return this.http.post(this.url +'/formation', {
      name: nameF,
      description: descriptionF,
      contact: contactF,
      prerequise: prerequiseF,
      competences:competenceF,
    });
  }

  postUser(email :string , password : string , role : string){
    return this.http.post(this.url +'/user', {
      email : email,
      password : password,
      role : role
    })
  }

  getCompetenceByRiasecPoints(realiste: number , investigateur : number , artistique : number , social : number , entreprenant : number , conventionnel : number  )
  {

    return this.http.post<FormationInterface[]>(this.url + '/competence/allRiasec' , {
      realiste : realiste,
      investigateur : investigateur,
      artistique : artistique,
      social : social,
      entreprenant : entreprenant,
      conventionnel : conventionnel,
    })
  }

}

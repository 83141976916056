import {Component, Input, OnInit} from '@angular/core';
import {ApiService, FormationInterface} from '../../../service/api.service';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';

@Component({
  selector: 'app-discipline-formation',
  templateUrl: './list-discipline.component.html',
  styleUrls: ['./list-discipline.component.css']
})
export class ListDisciplineComponent implements OnInit {

  @Input() discipline;
  isFavorite: boolean;
  hiddeScore: boolean;
  textFormation: any;
  page = 1;
  showFiller = false;
  data: FormationInterface[] = [];


  constructor(private api: ApiService, private router: Router , private cookieService: CookieService ) { }

  ngOnInit(): void {

  }

  SortByDis(value ,text , page ) {
    this.textFormation = text
    this.api.SortByDiscipline(value, page).toPromise().then(formation => this.data = formation['data']);

  }

  onSubmit() {

  }

  like() {
    this.isFavorite = !this.isFavorite

  }

  Navig(value, text) {

    this.router.navigateByUrl('/formation', { state: { value:value , text:text } });


  }
}

import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {ApiService, CompetenceInterface, FormationInterface} from '../../../service/api.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {element} from 'protractor';
import {CookieService} from 'ngx-cookie-service';


@Component({
  selector: 'app-profil',
  templateUrl: './profil.component.html',
  styleUrls: ['./profil.component.css']
})
export class ProfilComponent implements OnChanges {
  profilTitleOne: string;
  profilTitleTwo: string;
  profilParagraphOne: string;
  profilParagraphTwo: string;
  open: boolean = true;
  discipline: any;
  viewDiscipline: boolean = true;
  max;
  multi: any[];
  view: any[] = [250, 250];

  // options
  animations: boolean = true;
  xAxis: boolean = true;
  showYAxisLabel: boolean = true;
  showXAxisLabel: boolean = true;
  colorScheme = {
    domain: ['#003fff', '#1ae426', '#cf2da3', '#02ffe9', '#a8385d', '#aae3f5']
  };

  @Input()
  realiste: number;
  @Input()
  artistique: number;
  @Input()
  conventionnel: number;
  @Input()
  entreprenant: number;
  @Input()
  investigateur: number;
  @Input()
  social: number;
  @Input()
  sortComptences: any;
  @Input()
  RIASECSet: boolean;
  @Input()
  prerequisSet: boolean;
  @Input()
  isDone: boolean;
  @Input()
  sortPrerequisite: any;
  @Input()
  sortComptencesListe :any;

  public profilParagraphs: Array<string> = [
    'Vous avez les pieds sur Terre !',
    'Vous êtes un artiste !',
    'Vous êtes fidèle aux règles !',
    'Vous êtes un patron !',
    'Vous avez une grande soif de connaissance !',
    'Vous êtes à l\'aise socialement !',
  ];

  public riasecValues: Array<number> = [];


  data: FormationInterface[];
  comp: CompetenceInterface[];
  FormationArray: any;
  dataSort: FormationInterface[];
  dataQuestion: any;
  dataQuestion2: any;
  idFormation: any;
  prerequisite: any;
  QuestionCompetenceArray: any;
  panelOpenState: any;
  debugmod: boolean;
  private idQuestion: any;
  dataQuestionPrerequise;
  QuestionPrerequiseArray: any;
  newComp:CompetenceInterface[];
   questionComp: any;
   userTag: string;


  constructor(private api: ApiService , private _snackBar: MatSnackBar ,private cookieService: CookieService) {
    this.realiste = 0;
    this.artistique = 0;
    this.conventionnel = 0;
    this.entreprenant = 0;
    this.investigateur = 0;
    this.social = 0;
    this.questionComp = []
    this.sortComptences = [];
    this.dataQuestion = [];
    this.dataQuestion2 = [];
    this.newComp=[];
    this.dataQuestionPrerequise = [];
    this.data = [];
    this.dataSort = [];
    this.FormationArray = [];
    this.idFormation = []
    this.prerequisite = []
    this.comp = []
  }


  ngOnChanges(changes: SimpleChanges): void {

    this.SortPrerequisQuestions();
    this.SortCompetenceQuestions()
    this.riasecValues = [this.realiste, this.artistique, this.conventionnel, this.entreprenant, this.investigateur, this.social];
    if (!this.isDone){
      if (this.RIASECSet === true) {
        this.findByCompetance(this.realiste, this.investigateur, this.artistique, this.social, this.entreprenant, this.conventionnel).then(r => r)
      }
      //    this.getQuestionByPrerequise(this.prerequisite, this.dataQuestionPrerequise).then(r => r);

        this._snackBar.open(
         "Ces résultats sont à titre indicatif, penser à consulter un conseiller d'orientation pour vous aider dans vos démarches",
        "Fermer",
       {
                 duration: 15000,
                 horizontalPosition: "right"
               }
      );


    }
    else {
      console.log(this.riasecValues)
    }


    if (this.RIASECSet === true && this.isDone === false) {
      this.FormationArray.forEach(element => this.idFormation.push(element.id));

      }

    if (this.cookieService.get("GPSformationPREREQUIS").length !== 0){
      this.FormationArray = []
      let idFormation = this.cookieService.get("GPSformationPREREQUIS").split(',')
      idFormation.forEach(e => this.api.getFormationById(e).toPromise().then(f => this.FormationArray.push(f)))
      let idQuestionComp = this.cookieService.get("GPSquestionComp").split(',')
     idQuestionComp.forEach(e => this.api.getQuestionPrerequisById(e).toPromise().then(f => this.QuestionCompetenceArray.push(f)))

    }
    else{   if(this.prerequisSet === true && this.RIASECSet === false && this.isDone === false) {
     setTimeout( () => {this.FormationArray =  this.SortFormationByPrerequis() ; this.addcookie("GPSformationPREREQUIS")}, 2000)

    }}



    if (this.cookieService.get("GPSformationCOMPETENCE").length !== 0){
      this.FormationArray = []
      let idFormation = this.cookieService.get("GPSformationCOMPETENCE").split(',')
      idFormation.forEach(e => this.api.getFormationById(e).toPromise().then(f => this.FormationArray.push(f)))
    }
    else{
      if(this.prerequisSet === false && this.RIASECSet === false && this.isDone === false ) {


      setTimeout( () => {this.FormationArray =  this.SortFormationByComp() ; this.addcookie("GPSformationCOMPETENCE")}, 2000)
    }}

  }

  ngOnInit() {
    this.userTag = (Math.random() + 1).toString(36).substring(7);
    if (this.sortPrerequisite === undefined){
      this.sortPrerequisite = []
    }
    if ( this.comp === undefined){
      this.comp = []
    }

    if (this.sortComptences === undefined){
      this.sortComptences = []
    }

    if (this.cookieService.get(String("debugmod")) === "true")
    {
      this.debugmod = true
    }
  }


//Etape 1

  async findByCompetance(realiste: number , investigateur : number , artistique : number , social : number , entreprenant : number , conventionel : number  ) {

    await this.api.getCompetenceByRiasecPoints(realiste,investigateur,artistique,social,entreprenant,conventionel).toPromise().then(formation => formation.forEach(f => this.api.getFormationById(f['formation'].id).toPromise().then(listF => this.FormationArray.push(listF))))
    await this.api.getCompetenceByRiasecPoints(realiste,investigateur,artistique,social,entreprenant,conventionel).toPromise().then(formation => formation.forEach(f => this.api.getFormationById(f['formation'].id).toPromise().then(listPre =>listPre.prerequisite.forEach(e =>this.prerequisite.push(e) ))))
    await this.api.getCompetenceByRiasecPoints(realiste,investigateur,artistique,social,entreprenant,conventionel).toPromise().then(formation => formation.forEach(f => this.api.getFormationById(f['formation'].id).toPromise().then(listPre =>listPre.competences.forEach(e =>this.comp.push(e) ))))
    this.addcookie("GPSformationRIASEC")

    if (!this.isDone ){
      const nameForm = []
      this.FormationArray.forEach(e => nameForm.push(e.discipline))
      this.getDiscipline(nameForm)
      console.log(nameForm ,this.discipline, "C LES DISIPLINE LA OU QUOI")

      if(this.realiste !== 0){
      this.api.postCom('[QUESTIONNAIRE1],'+ this.userTag + ',' +this.realiste + ',' + this.investigateur + ',' + this.artistique + ',' + this.social + ',' + this.entreprenant + ',' + this.conventionnel  + ',' +   nameForm.toString()).toPromise().then()
    }}
  }


  //REDUIR LE NOMBRE DE QUESTION DE PREREQUIS ETAPE 1BIS
  SortPrerequisQuestions(){
    let arrayIdPrequisite = [];
    let keysSorted = [];
    this.QuestionPrerequiseArray = []
    this.prerequisite.forEach(element => arrayIdPrequisite.push(element.id))
    let count = {};
    arrayIdPrequisite.forEach(function(i) { count[i] = (count[i]||0) + 1;});

    keysSorted = Object.keys(count).sort(function(a,b){return count[b]-count[a]})


    //sliced : les id des questions de prerequis dans l'ordre de points du plus haut au moins haut


    const sliced = Object.keys(keysSorted).reduce((result, key) => {
      result[key] = keysSorted[key];

      return result;
    }, {});


    // b = les id de sliced qui alterne avec le plus haut et le moins haut

    let a = []
    Object.keys(sliced).forEach(key => {a.push(sliced[key])  })

    let b =[];

    let l = a.length-1;  // micro optimization
    let L = l/2;         // micro optimization
    for(var i=0; i<L; i++) b.push( a[l-i] ,a[i] );
    if(a.length%2) b.push( a[i] ); // add last item in odd arrays



    b.slice(0,15).forEach(key => {
      console.log(sliced[key]);
      this.api.getPrerequisiteById(sliced[key]).toPromise().then(prerequisite => prerequisite['question'].forEach(elem => this.QuestionPrerequiseArray.push(elem)
      ))});

    //sliced ===== Liste des id des prerequis trié au max coupé par 10 reste juste a recup les questions

    console.log(this.QuestionPrerequiseArray)

  }

  async getFormationByPrerequisite(array, data) {
    array.forEach(element => this.api.getPrerequisiteById(element.id).toPromise().then(formation => formation['formations'].forEach(elem => data.push(elem)
    )));
    this.FormationArray = data.filter((thing, index) => {
      const _thing = JSON.stringify(thing);
      return index === data.findIndex(obj => {
        return JSON.stringify(obj) === _thing;
      });
    });
  }

//Sort des nouvelles formation par rapport aux questions prerequis (Etape 2)



  SortFormationByPrerequis(){
    let FuturForma = [];
    let cutNum = this.FormationArray.length
    for (const element of this.sortPrerequisite) {
      this.FormationArray.forEach(elem =>
        elem.prerequisite.forEach(function f(e) {
            if (e.id == element.id) {

              FuturForma.push({id: elem.id , name: elem.name,description:elem.description,contact : elem.contact,competences: elem.competences,prerequisite: elem.prerequisite, avatar : elem.avatar, discipline : elem.discipline , point: element.point / elem.prerequisite.length })
              FuturForma.forEach(function (x) {
                if (x.name == elem.name) {
                  let pos = FuturForma.map(function (e) {
                    return e.name;
                  }).indexOf(elem.name);
                  FuturForma[pos].point = FuturForma[pos].point + element.point / elem.prerequisite.length
                } else {

                }
              })

            }

          }
        )
      )
    }
    if (!this.isDone){
      const nameForm = []
      this.FormationArray.forEach(e => nameForm.push('[ id : ' + e.point +'] ' + e.name ))
      this.api.postCom( '[QUESTIONNAIRE2]: ' +'Utilisateur ' + this.userTag + ' Formation:' + nameForm.toString()).toPromise().then()
    }
    return FuturForma.splice(0 , cutNum ).sort((a, b) => b.point - a.point).splice(0, 10)
//Liste des formation à cut


   }


  //Etape 2bis
  SortCompetenceQuestions(){

    let arrayIdCompetence = [];
    let keysSorted = [];
    this.QuestionCompetenceArray = []
    this.comp.forEach(element => arrayIdCompetence.push(element.id))
    let count = {};
    arrayIdCompetence.forEach(function(i) { count[i] = (count[i]||0) + 1;});

    keysSorted = Object.keys(count).sort(function(a,b){return count[b]-count[a]})


    const sliced = Object.keys(keysSorted).slice(0, 15).reduce((result, key) => {
      result[key] = keysSorted[key];

      return result;
    }, {});


    let idQuestionComp = []
    if (this.cookieService.get("GPSquestionComp").length === 0 ) {
      setTimeout(() => {
        this.QuestionCompetenceArray.forEach(e => idQuestionComp.push(e.id))
      }, 2000)
      setTimeout(() => {
        this.cookieService.set("GPSquestionComp", idQuestionComp.toString())
      }, 3000)
    }

    let a = []
    Object.keys(sliced).forEach(key => {a.push(sliced[key])  })

    let b =[];

    let l = a.length-1;  // micro optimization
    let L = l/2;         // micro optimization
    for(var i=0; i<L; i++) b.push( a[l-i] ,a[i] );
    if(a.length%2) b.push( a[i] ); // add last item in odd arrays



    b.slice(0,15).forEach(key => {
      console.log(sliced[key]);
      this.api.getCompetenceById(sliced[key]).toPromise().then(competences => competences['question'].forEach(elem => this.QuestionCompetenceArray.push(elem)
      ))});

    //sliced ===== Liste des id des competences trié au max coupé par 10 reste juste a recup les questions

    console.log(this.QuestionCompetenceArray)
  }


  consolelog(){
    console.log(this.QuestionCompetenceArray)
  }

//Etape 3
  SortFormationByComp(){
    let ArrPre = this.comp;
    let FuturForma = [];
    let cutNum = this.FormationArray.length
    for (const element of this.sortComptencesListe) {
      this.FormationArray.forEach(elem =>
        elem.competences.forEach(function f(e) {
            if (e.id == element.id) {

              FuturForma.push({id: elem.id , name: elem.name,description:elem.description,contact : elem.contact,competences: elem.competences,prerequisite: elem.prerequisite,avatar : elem.avatar,discipline:elem.discipline, point: element.point / elem.competences.length})
              FuturForma.forEach(function (x) {
                if (x.name == elem.name) {
                  let pos = FuturForma.map(function (e) {
                    return e.name;
                  }).indexOf(elem.name);
                  FuturForma[pos].point = FuturForma[pos].point + element.point / elem.competences.length
                } else {

                }
              })

            }

          }
        )
      )
    }

    if (!this.isDone){
      const nameForm = []
      this.FormationArray.forEach(e => nameForm.push('[ id : ' + e.id +'] ' + e.name ))
      this.api.postCom( '[QUESTIONNAIRE3]: ' +'Utilisateur ' + this.userTag + ' Formation:' + nameForm.toString()).toPromise().then()
    }
    return FuturForma.splice(0 , cutNum ).sort((a, b) => b.point - a.point).splice(0, 5)
//Liste des formation à cut
  }


  /*
      checkDuplicate(arr) {
      let result = false;
      // create a Set with array elements
      const s = new Set(arr);
      // compare the size of array and Set
      if(arr.length !== s.size){
        result = true;
      }
      if(result) {
        console.log('Array contains duplicate elements');
      } else {
        console.log('Array does not contain duplicate elements');
        this.SortPrerequisQuestions()

    }
    }
    async getQuestionByPrerequise(arrayQuestionPrerequis, dataPrerequise) {

      console.log(arrayQuestionPrerequis)
      this.checkDuplicate(arrayQuestionPrerequis)
        arrayQuestionPrerequis.forEach(element => this.api.getPrerequisiteById(element.id).toPromise().then(prerequisite => prerequisite['question'].forEach(elem => dataPrerequise.push(elem))
      ));
      this.QuestionPrerequiseArray = await dataPrerequise.filter((thing, index) => {
        const _thing = JSON.stringify(thing);
        return index === dataPrerequise.findIndex(obj => {
          return JSON.stringify(obj) === _thing;
        });
      });
    }
  */

  //tri une liste de discipline
  getDiscipline(discipinePasTriee) {
    const num = discipinePasTriee
    let listDisp = [];
    const count = num =>
      num.reduce((a, b) => ({ ...a,
        [b]: (a[b] || 0) + 1
      }), {})

    listDisp = count(num)

    this.discipline = Object.keys(listDisp)
    console.log(this.discipline)

  }

  addcookie(name){
  console.log("COOOOOOOOOOOKIE")
    if (name === "GPSformationPREREQUIS" ){
      this.cookieService.set("GPSformationRIASEC",'')
    }

    if (name === "GPSformationCOMPETENCE"){
      this.cookieService.set("GPSformationPREREQUIS",'')
    }

    if (name === "GPSformationRIASEC" ){
      this.cookieService.set("GPSformationCOMPETENCE",'')
      this.cookieService.set("GPSformationPREREQUIS",'')
    }

    let idForm = []
    this.FormationArray.forEach(e => idForm.push(e.id) )
    this.cookieService.set(name,idForm.toString())
  }




//ancienne fonction pour chercher les comptences avec les 2 profile riasec les plus elevé
  async getCompetence(comp1: string, comp2: string) {
    await this.api.getCompetenceByRiasec(comp1, comp2).toPromise().then(competence => this.comp = competence);

    if (this.comp !== undefined) {
      for (let i = 0; i < this.comp.length ; i++) {
        if (this.comp[i].point == undefined) {
          this.comp[i].point = 0;
        }
      }
    }
  }

}

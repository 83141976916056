<div class="width row "  >

  <div class=" colC"  [@inOutAnimation] >
    <div class="customCard card card-chart" (click)="DialogBoxR = false ; DialogBoxL = true"  [ngClass]="{'clickedCard' :DialogBoxL ,'miniCard' : DialogBoxR || DialogBoxL }  " >
      <div class=" card-body">
        <div class="">
          <div style="display: block">

            <img src="../assets/img/NSEO.svg" alt="" class="img-picto">
            <h2 style="text-align: center" [ngClass]="DialogBoxR || DialogBoxL ? 'texthidden' : ''">S'orienter après le BAC</h2>
          </div>
          <!--   <p *ngFor="let formm of allDisiplineList | keyvalue">{{formm.key}} {{formm.value}}</p> -->
          <div class="">

          </div>
        </div>
      </div>
    </div>
  </div>
  <div class=" colC" [@inOutAnimation]  [ngClass]="DialogBoxR || DialogBoxL ? 'cardDeck' : ''">
    <div class="customCard card card-chart"  (click)="DialogBoxR = true ; DialogBoxL = false"  [ngClass]="{'clickedCard' :DialogBoxR ,'miniCard' : DialogBoxR || DialogBoxL }  ">
      <div class=" card-body">
        <div class="">
          <div style="display: block">

            <img src="../assets/img/chapeau.svg" alt="" class="img-picto">
            <h2 style="text-align: center"  [ngClass]="DialogBoxR || DialogBoxL ? 'texthidden' : ''" >Formations de l'UHA</h2>
          </div>

        </div>
      </div>
    </div>
  </div>
  <div class=" colC" [@inOutAnimation]  [ngClass]="DialogBoxR || DialogBoxL ? 'cardDeckS' : ''">
    <div class="customCardDisab card card-chart" [ngClass]="DialogBoxR || DialogBoxL ? 'miniCard' : ''" >
      <div class="diagonalbadge" [ngClass]="DialogBoxR || DialogBoxL ? 'texthidden' : ''">Prochainement</div>
      <div class=" card-body">
        <div class="">
          <div style="display: block">

            <img src="../assets/img/choix.svg" alt="" class="img-picto">
            <h2 style="text-align: center;" [ngClass]="DialogBoxR || DialogBoxL ? 'texthidden' : ''">Construire un parcours<br> à l'UHA</h2>


          </div>

        </div>
      </div>

    </div>
  </div>

</div>

<div class="dialogueBoxR" [@inOutAnimation] *ngIf="DialogBoxR">
  <div  class="DialogTest">
    <h1 class="FontsizeT">Liste des formations de l'UHA</h1>
    <p class="FontsizeP" > Vous trouvrez toute les formations et les domaines de formation de l'Université de Haute Alsace</p>
    <div style="text-align: center" >
      <button class="answerNew borderButtonB" (click)="onSubmit('orientation')">Chercher une formation!</button>

    </div>
  </div>
  <img src="assets/img/persoOrientation.svg" class="imgFixR" alt="">
</div>
<div class="dialogueBoxL" [@inOutAnimation] *ngIf="DialogBoxL">
  <div  class="DialogTest">
    <h1 class="FontsizeT">S'orienter après le bac</h1>
    <p class="FontsizeP"> Le goELAN permet d'en savoir plus sur les chemins d'orientation qui correspondent aux attentes des futurs étudiants.

      En répondant à 3 séries de questions portant sur des intérêts, des compétences développées au lycée, et celles développées à l'université, le GOELAN propose des chemins correspondant le mieux à chaque profil.</p>
    <div style="text-align: center" >
      <button class="answerNew borderButtonB" (click)="onSubmit('presentation')">S'orienter !</button>

    </div>
  </div>
  <img src="assets/img/machin.svg" class="imgFixL" alt="">
</div>
<div style="position: absolute ; top: 0; left: 0 ; width: 100% ; height: 100%;" *ngIf="DialogBoxL || DialogBoxR" (click)="DialogBoxR = false ; DialogBoxL = false"></div>
<!--
<div class="dialogueBoxL" [@inOutAnimation]  *ngIf="DialogBoxL">
  <img src="assets/img/persoOrientation.svg" class="imgFixL">
</div>


<div class=wrapper style=" ">
  <div class="divimage imgTest"></div>
</div>

<div class="explication " id="container">

  <div class="positon" >
    <div class="wrapper first" style="">
      <div class="divimage img1"></div>
      <div class=divtext> Bienvenue sur GOELAN   <h1>GOELAN est une suite d'outils qui permet d'aider dans les démarches d'orientation à travers differents tests.<br>Il offre aussi la possibilité d'explorer les formations de l'UHA</h1>
      </div>
     </div>
    <div class="wrapper " style="">
      <div class="divimage img3"></div>
    </div>
    <div class=wrapper style="left: 60%;margin-top: 30% ">
      <div class="divimage img5" style="width: 80%;"></div>
    </div>
  </div>

  <div class="positon">
    <div class=wrapper  style="margin-top: 55% ; right: 15%">
      <div class="divimage img2"></div>
      <div class=divtext>Text à rédiger</div>
    </div>

    <div class="wrapper" style="margin-top: 55% ; left: -40%;
  transform: rotate(80deg); ">
      <div class="divimage img5" style="width: 60%;"></div>
    </div>
    <div class=wrapper style="margin-top: 77% ; left: 10%;">
      <div class="divimage img4"></div>
    </div>

    <div class=wrapper style="margin-top: 120%;">
      <div class="divimage img1"></div>
      <div class=divtext>Text à rédiger</div>
    </div>&ndash;&gt;
  </div>

</div>
-->

import {AfterViewInit, Component, Input, NgModule, OnInit} from '@angular/core';
import {ApiService, FormationInterface, PageFormationInterface} from '../../../service/api.service';
import {Observable, Subscription} from 'rxjs';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-formation',
  templateUrl: './formation.component.html',
  styleUrls: ['./formation.component.css'],
})
export class FormationComponent implements OnInit{
  page = 1;
  showFiller = false;
  data: FormationInterface[] = [];
  formationName: string;
  formationDesctiption: string;
  formationAvatar: string;
  loader : boolean = false

  text: string;
  active: boolean;
  textFormation: any;

  constructor( private api: ApiService,
               private route: ActivatedRoute) { }

  ngOnInit(): void {

  this.loader = true

    this.text = this.route.snapshot.paramMap.get('text');
    console.log(this.text , history.state['value']);
    if (this.text === null) {

      if(history.state['value'] === undefined){

     this.getByLimit()
      }
      else {
      this.textFormation = history.state['text']
      this.api.SortByDiscipline(history.state['value'], 1).toPromise().then(formation => this.data = formation['data'])
        this.api.SortByDiscipline(history.state['value'], 1).toPromise().then(formation => this.loader = false)

    }} else {
      this.getByTextPaginate(this.text, this.page).then(r => console.log(r , ''));
    }

  }

  async  getApi() {
    await this.api.getAll().toPromise().then(formation => this.data = formation);
    await this.api.getAll().toPromise().then(formation => this.loader = false);

  }

  async getByLimit() {
    this.textFormation = 'Liste des Formations'
    await this.api.getAllLimit(this.page).toPromise().then(formation => this.data = formation['data'] );
    await this.api.getAllLimit(this.page).toPromise().then(formation => this.loader = false);
    console.log(this.data);
  }

  async nextPage() {
    this.page += 1;
    if (this.text === null) {
      await this.api.getAllLimit(this.page).toPromise().then(formation => this.data = formation['data'] );
      await this.api.getAllLimit(this.page).toPromise().then(formation => this.loader = false );
    }
    else {
      await this.getByTextPaginate(this.text, this.page);
    }
  }
  async previousPage() {
    this.page -= 1;
    if (this.text === null) {
      await this.api.getAllLimit(this.page).toPromise().then(formation => this.data = formation['data'] );
      await this.api.getAllLimit(this.page).toPromise().then(formation => this.loader = false );
    }
    else {
      await this.getByTextPaginate(this.text, this.page);
    }
  }

  async firstPage() {
    this.page = 1;
    if (this.text === null) {
      await this.api.getAllLimit(this.page).toPromise().then(formation => this.data = formation['data'] );
      await this.api.getAllLimit(this.page).toPromise().then(formation => this.loader = false );
    }
    else {
      await this.getByTextPaginate(this.text, this.page);
    }
  }
  async navigate(page) {


    this.page = page
    if (this.text === null) {
      await this.api.getAllLimit(page).toPromise().then(formation => this.data = formation['data'] );
      await this.api.getAllLimit(page).toPromise().then(formation => this.loader = false);
    }
    else {
      await this.getByTextPaginate(this.text, this.page);
    }
  }
  async getByTextPaginate(text: string, page) {
    this.api.getByTextLimit(text, page).toPromise().then(formation => this.data = formation['data']);
    this.api.getByTextLimit(text, page).toPromise().then(formation => this.loader = false);

  }

  Tri() {
    let input, filter, ul, li, a, i, txtValue;
    input = document.getElementById("myInput");
    filter = input.value.toUpperCase();
    ul = document.getElementById("myUL");
    li = ul.getElementsByTagName("button");
    for (i = 0; i < li.length; i++) {
      a = li[i].getElementsByTagName("a")[0];
      txtValue = a.textContent || a.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = "";
      } else {
        li[i].style.display = "none";
      }
    }
  }


  SortByDis(value ,text , page ) {
    this.textFormation = text
    this.api.SortByDiscipline(value, page).toPromise().then(formation => this.data = formation['data']);
    this.api.SortByDiscipline(value, page).toPromise().then(formation => this.loader = false);

  }
}

<div class="row-cols-2" style="text-align: center; padding-left: 30%; padding-top: 5% ">
      <h1>Ajouter une compétence</h1>
      <br>
      <br>
      <form class="form-group" [formGroup]="addCompetence">
        <h2>Nom de la compétence :</h2>
        <input class="form-control" type="text" formControlName="name" id="name" placeholder="Nom de la compétence">

        <br>

        <h2>Type de compétence</h2>
        <select class="form-control" formControlName="type" id="type">
          <option value="disciplinaire">disciplinaire</option>
          <option value="transversal">transversal</option>
        </select>
        <br>
        <h2>Type de profil</h2>
        <mat-form-field appearance="fill" class="col-lg-12">
          <mat-label></mat-label>
          <mat-select formControlName="profil" multiple class="form-control">
            <mat-option *ngFor="let profils of profilList" [value]="profils.profilName">
              {{profils.profilName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <br>
        <button class="col-lg-12 btn btn-secondary" (click)="this.submitForm()">Ajouter</button>
      </form>
</div>

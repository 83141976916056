<div class="loader" *ngIf="loader === true" xmlns="http://www.w3.org/1999/html"> <div style="
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
margin-top: 20%"><div style="
  width: 200px;
  height: 200px;">
  <div class="load"></div>
</div></div>
</div>



<div class="drawer-container">

  <div class="divVerticalLoad">
    <div class="divVerticalLoadBleu" [ngClass]="{'divVerticalLoadBleu2' : prerequisSet ,'divVerticalLoadBleu3' : !RIASECSet && !prerequisSet}">
    </div>
    <circle-progress
      class="rondedStapRiasec"
      [percent]="pourcentR"
      [radius]="40"
      [showSubtitle]="false"
      [title]="'1'"
      [backgroundColor]="'#ecedf7'"
      [backgroundPadding]="-20"
      [showUnits]="false"
      [startFromZero]="false"
      [outerStrokeWidth]="10"
      [innerStrokeWidth]="8"
      [outerStrokeColor]="'#00a6ff'"
      [showTitle]="true"
      [innerStrokeColor]="'#8a7f7f'"
      [animation]="true"
      [animationDuration]="300"
    ></circle-progress>
    <circle-progress
      class="rondedStapPre"
      [percent]="pourcentP"
      [title]="'2'"
      [showSubtitle]="false"
      [showUnits]="false"
      [radius]="40"
      [startFromZero]="false"
      [outerStrokeWidth]="10"
      [innerStrokeWidth]="8"

      [backgroundColor]="'#ecedf7'"
      [backgroundPadding]="-20"
      [outerStrokeColor]="'#e5f318'"
      [innerStrokeColor]="'#8a7f7f'"
      [animation]="true"
      [animationDuration]="300"
    ></circle-progress>
    <circle-progress
      class="rondedStapCom"
      [percent]="pourcentC"
      [title]="'3'"
      [radius]="40"
      [startFromZero]="false"
      [backgroundColor]="'#ecedf7'"
      [backgroundPadding]="-20"
      [showSubtitle]="false"
      [showUnits]="false"
      [outerStrokeWidth]="10"
      [innerStrokeWidth]="8"
      [toFixed]="2"
      [outerStrokeColor]="'rgb(106,199,43)'"
      [innerStrokeColor]="'#8a7f7f'"
      [animation]="true"
      [animationDuration]="300"
    ></circle-progress>
  </div>

  <div  class="drawer-content container" mode="side" *ngIf="hiddenDrawer">
    <div class="textDrawer" [ngClass]="{'textDrawerY' : prerequisSet ,'textDrawerG' : !RIASECSet && !prerequisSet}">

      <p>{{isDone ? "Questions " : "Résultats "}}{{RIASECSet ? "Riasec" : ""}}{{prerequisSet ? "Prérequis" : ""}} {{!RIASECSet && !prerequisSet ? "Compétences" : ""}}</p>
    </div>



    <div class="textExplication"  [hidden]=!isDone *ngIf="RIASECSet" >
      <p>Répondez aux questions pour identifier vos types de personnalités en milieu professionnel en lien avec vos intérêts professionnels. Voici les 6 types de personnalités analysés :<br><br>

        - <b>Réaliste</b>  : Besoin d'être impliqué physiquement dans ce qu'il fait.<br>
        - <b>Investigateur</b>  : Habité par une soif de connaissances et de savoir.<br>
        - <b>Artistique</b>  : Souhaite exprimer ses émotions ou pensées à travers des formes d'art.<br>
        - <b>Social</b>  : Attiré par les activités favorisant le contact avec les autres, particulièrement dans le but de les aider.<br>
        - <b>Entreprenant </b> : Aime avoir des responsabilités, surmonter des défis dans l'espoir de se hisser au sommet.<br>
        - <b>Conventionnel</b>  : Désire respecter les normes, consignes et règles.<br> </p>


    </div>


    <div [hidden]=!isDone *ngIf="prerequisSet"  >
      <p>La deuxième étape sert à trouver quel domaine t'intéresse dans les différentes formations</p><br>
    </div>

    <div [hidden]=!isDone *ngIf="!prerequisSet && !RIASECSet"  >

      <p>La derniere étape sert à touver les compétences que tu veux acquérir  </p><br>
    </div>



    <div class="textExplication"  [hidden]=isDone>
     <button class="myButtonLG col "*ngIf="RIASECSet === true && loader === true"  [hidden]=isDone > Etape suivante - Affiner mon profil ?</button>
      <button class="myButtonLG col "*ngIf="RIASECSet === false  && prerequisSet ===true  && loader === true"  [hidden]=isDone > Etape suivante - Affiner mon profil ?</button>

      <button class="myButtonL col "*ngIf="RIASECSet === true && loader === false"  [hidden]=isDone (click)="isDone = true; RIASECSet = false; prerequisSet = true ;compteurQuestions = 0; competenceSet = false "> Etape suivante - Affiner mon profil ?</button>

      <button class="myButtonL col" *ngIf="RIASECSet === false  && prerequisSet ===true && loader === false" [hidden]=isDone (click)="isDone = true; RIASECSet = false; compteurQuestions = 0; prerequisSet = false ;competenceSet = true" > Etape suivante - Affiner mon profil ?</button>

      <button class="myButtonL col"   [hidden]=isDone (click)="generatePdf()" > Faire un export de votre profil (.pdf)</button>
      <button class="myButtonL col" [hidden]=isDone (click)="reload()"  >Recommencer le test ?</button>


    </div>
    <p style="text-align: center ;margin-top : 5%" [hidden]=isDone> Vous êtes {{profilTitleOne}} et {{profilTitleTwo}}</p>

    <div style="padding-left: 10%" >
    <ngx-charts-polar-chart  [hidden]=isDone
      class="charts"
      [view]="view"
      [scheme]="colorScheme"
      [showXAxisLabel]="showXAxisLabel"
      [showYAxisLabel]="showYAxisLabel"
      [xAxis]="xAxis"
      [results]="multi" >
    </ngx-charts-polar-chart></div>

    <img src="../assets/img/RIASEC.png" [hidden]=!isDone class="imageFix">

  </div>





  <div style="padding-left:25%">
    <app-profil [hidden]=isDone  [realiste]="realiste" [artistique]="artistique" [investigateur]="investigateur" [social]="social" [entreprenant]="entreprenant" [conventionnel]="conventionnel" [sortComptences]="sortComptences" [RIASECSet] = "RIASECSet" [prerequisSet]="prerequisSet" [sortPrerequisite]="sortPrerequisite" [sortComptencesListe]="sortComptencesListe" [isDone] ="isDone"> </app-profil>
  </div>
  <div [hidden]=!isDone>


    <div>


    <div class="textMainQuestion question-label bubble bubble-bottom-left*" *ngIf="RIASECSet === true">Aimeriez-vous {{questionsRiasec[compteurQuestions].name | lowercase}} ? </div>
    <div class="textMainQuestion question-label bubbleY bubble-bottom-leftY*"  *ngIf="prerequisSet === true && isDone === true"> {{child.QuestionPrerequiseArray[compteurQuestions].name    }} ? </div>
    <div class="textMainQuestion question-label bubbleG bubble-bottom-leftG*" *ngIf="RIASECSet === false && prerequisSet ===false && isDone === true" >{{child.QuestionCompetenceArray[compteurQuestions].name  }} </div>

      <!--
      <img *ngIf="RIASECSet === true" src='{{"../assets/img/mascotte" +randNum + ".png" }}' class="mascotte" >
      <img *ngIf="prerequisSet === true " src='{{"../assets/img/mascotteOrange.png" }}' class="mascotte"  >
      <img *ngIf="RIASECSet === false && prerequisSet ===false" src='{{"../assets/img/mascotteVert.png" }}' class="mascotte" >
      -->

    <div class="boxAnswer"  *ngIf="RIASECSet === true">
      <button class="answerNew borderButtonB" (mouseout)="checked1 = false" (mouseover)="checked1 = true" (click)="NextQuestion(); addPointTo(1)">Pas du tout<br>
        <div class="feedback"><label class="angry">
          <input type="radio" value="1" name="feedback" checked={{checked1}} >
          <div>
            <svg class="eye left">
              <use xlink:href="#eye">
                <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 4" id="eye">
                  <path d="M1,1 C1.83333333,2.16666667 2.66666667,2.75 3.5,2.75 C4.33333333,2.75 5.16666667,2.16666667 6,1"></path>
                </symbol>
              </use></svg>
            <svg class="eye right">
              <use xlink:href="#eye">
              </use></svg>
            <svg class="mouth">
              <use xlink:href="#mouth">
                <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 7" id="mouth">
                  <path d="M1,5.5 C3.66666667,2.5 6.33333333,1 9,1 C11.6666667,1 14.3333333,2.5 17,5.5"></path>
                </symbol>
              </use></svg>
          </div>
        </label></div></button>
      <button class="answerNew borderButtonB"  (mouseout)="checked2 = false" (mouseover)="checked2 = true" (click)="NextQuestion(); addPointTo(2)">Pas tellement<br>
        <div class="feedback"><label class="sad">
          <input type="radio" value="1" name="feedback" checked={{checked2}}>
          <div>
            <svg class="eye left">
              <use xlink:href="#eye">
                <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 4" id="eye">
                  <path d="M1,1 C1.83333333,2.16666667 2.66666667,2.75 3.5,2.75 C4.33333333,2.75 5.16666667,2.16666667 6,1"></path>
                </symbol>
              </use></svg>
            <svg class="eye right">
              <use xlink:href="#eye">
              </use></svg>
            <svg class="mouth">
              <use xlink:href="#mouth">
                <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 7" id="mouth">
                  <path d="M1,5.5 C3.66666667,2.5 6.33333333,1 9,1 C11.6666667,1 14.3333333,2.5 17,5.5"></path>
                </symbol>
              </use></svg>
          </div>
        </label></div></button>
      <button class="answerNew borderButtonB"  (mouseout)="checked3 = false" (mouseover)="checked3 = true"  (click)="NextQuestion(); addPointTo(3)">Un peu<br>
        <div class="feedback"><label class="good">
          <input type="radio" value="1" name="feedback" checked={{checked3}}>
          <div>
            <svg class="eye left">
              <use xlink:href="#eye">
                <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 4" id="eye">
                  <path d="M1,1 C1.83333333,2.16666667 2.66666667,2.75 3.5,2.75 C4.33333333,2.75 5.16666667,2.16666667 6,1"></path>
                </symbol>
              </use></svg>
            <svg class="eye right">
              <use xlink:href="#eye">
              </use></svg>
            <svg class="mouth">
              <use xlink:href="#mouth">
                <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 7" id="mouth">
                  <path d="M1,5.5 C3.66666667,2.5 6.33333333,1 9,1 C11.6666667,1 14.3333333,2.5 17,5.5"></path>
                </symbol>
              </use></svg>
          </div>
        </label></div></button>
      <button class="answerNew borderButtonB"  (mouseout)="checked4 = false" (mouseover)="checked4 = true" (click)="NextQuestion(); addPointTo(4)">Beaucoup<br>
        <div class="feedback"><label class="happy">
          <input type="radio" value="1" name="feedback" checked={{checked4}}>
          <div>
            <svg class="eye left">
              <use xlink:href="#eye">
                <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 4" id="eye">
                  <path d="M1,1 C1.83333333,2.16666667 2.66666667,2.75 3.5,2.75 C4.33333333,2.75 5.16666667,2.16666667 6,1"></path>
                </symbol>
              </use></svg>
            <svg class="eye right">
              <use xlink:href="#eye">
              </use></svg>
            <svg class="mouth">
              <use xlink:href="#mouth">
                <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 7" id="mouth">
                  <path d="M1,5.5 C3.66666667,2.5 6.33333333,1 9,1 C11.6666667,1 14.3333333,2.5 17,5.5"></path>
                </symbol>
              </use></svg>
          </div>
        </label></div> </button>
      <div *ngIf=debugmod> <p>Realiste :{{realiste}}| Investigateur :{{ investigateur}}| Artistique :{{artistique}}| Social :{{social}}| Entreprenant :{{entreprenant}}| Conventionnel :{{conventionnel }}</p></div>


    </div>



    <div class="boxAnswer"  *ngIf="prerequisSet === true">

      <button class="answerNew borderButtonY" (mouseout)="checked1 = false" (mouseover)="checked1 = true" (click)="NextQuestion(); addPointTo(0)">Débutant<br>
        <div class="feedback"><label class="angry">
          <input type="radio" value="1" name="feedback" checked={{checked1}} >
          <div>
            <svg class="eye left">
              <use xlink:href="#eye">
                <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 4" id="eye">
                  <path d="M1,1 C1.83333333,2.16666667 2.66666667,2.75 3.5,2.75 C4.33333333,2.75 5.16666667,2.16666667 6,1"></path>
                </symbol>
              </use></svg>
            <svg class="eye right">
              <use xlink:href="#eye">
              </use></svg>
            <svg class="mouth">
              <use xlink:href="#mouth">
                <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 7" id="mouth">
                  <path d="M1,5.5 C3.66666667,2.5 6.33333333,1 9,1 C11.6666667,1 14.3333333,2.5 17,5.5"></path>
                </symbol>
              </use></svg>
          </div>
        </label></div></button>
      <button class="answerNew borderButtonY"  (mouseout)="checked2 = false" (mouseover)="checked2 = true" (click)="NextQuestion(); addPointTo(1)">Intermédiaire<br>
        <div class="feedback"><label class="sad">
          <input type="radio" value="1" name="feedback" checked={{checked2}}>
          <div>
            <svg class="eye left">
              <use xlink:href="#eye">
                <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 4" id="eye">
                  <path d="M1,1 C1.83333333,2.16666667 2.66666667,2.75 3.5,2.75 C4.33333333,2.75 5.16666667,2.16666667 6,1"></path>
                </symbol>
              </use></svg>
            <svg class="eye right">
              <use xlink:href="#eye">
              </use></svg>
            <svg class="mouth">
              <use xlink:href="#mouth">
                <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 7" id="mouth">
                  <path d="M1,5.5 C3.66666667,2.5 6.33333333,1 9,1 C11.6666667,1 14.3333333,2.5 17,5.5"></path>
                </symbol>
              </use></svg>
          </div>
        </label></div></button>
      <button class="answerNew borderButtonY"  (mouseout)="checked3 = false" (mouseover)="checked3 = true"  (click)="NextQuestion(); addPointTo(2)">Confirmé<br>
        <div class="feedback"><label class="good">
          <input type="radio" value="1" name="feedback" checked={{checked3}}>
          <div>
            <svg class="eye left">
              <use xlink:href="#eye">
                <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 4" id="eye">
                  <path d="M1,1 C1.83333333,2.16666667 2.66666667,2.75 3.5,2.75 C4.33333333,2.75 5.16666667,2.16666667 6,1"></path>
                </symbol>
              </use></svg>
            <svg class="eye right">
              <use xlink:href="#eye">
              </use></svg>
            <svg class="mouth">
              <use xlink:href="#mouth">
                <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 7" id="mouth">
                  <path d="M1,5.5 C3.66666667,2.5 6.33333333,1 9,1 C11.6666667,1 14.3333333,2.5 17,5.5"></path>
                </symbol>
              </use></svg>
          </div>
        </label></div></button>
      <button class="answerNew borderButtonY"  (mouseout)="checked4 = false" (mouseover)="checked4 = true" (click)="NextQuestion(); addPointTo(3)">Expert<br>
        <div class="feedback"><label class="happy">
          <input type="radio" value="1" name="feedback" checked={{checked4}}>
          <div>
            <svg class="eye left">
              <use xlink:href="#eye">
                <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 4" id="eye">
                  <path d="M1,1 C1.83333333,2.16666667 2.66666667,2.75 3.5,2.75 C4.33333333,2.75 5.16666667,2.16666667 6,1"></path>
                </symbol>
              </use></svg>
            <svg class="eye right">
              <use xlink:href="#eye">
              </use></svg>
            <svg class="mouth">
              <use xlink:href="#mouth">
                <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 7" id="mouth">
                  <path d="M1,5.5 C3.66666667,2.5 6.33333333,1 9,1 C11.6666667,1 14.3333333,2.5 17,5.5"></path>
                </symbol>
              </use></svg>
          </div>
        </label></div> </button>

    </div>

    <div class="boxAnswer"  *ngIf="RIASECSet === false && prerequisSet ===false">
      <button class="answerNew borderButtonY" (mouseout)="checked1 = false" (mouseover)="checked1 = true" (click)="NextQuestion(); addPointTo(0)">Très peu important<br>
        <div class="feedback"><label class="angry">
          <input type="radio" value="1" name="feedback" checked={{checked1}} >
          <div>
            <svg class="eye left">
              <use xlink:href="#eye">
                <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 4" id="eye">
                  <path d="M1,1 C1.83333333,2.16666667 2.66666667,2.75 3.5,2.75 C4.33333333,2.75 5.16666667,2.16666667 6,1"></path>
                </symbol>
              </use></svg>
            <svg class="eye right">
              <use xlink:href="#eye">
              </use></svg>
            <svg class="mouth">
              <use xlink:href="#mouth">
                <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 7" id="mouth">
                  <path d="M1,5.5 C3.66666667,2.5 6.33333333,1 9,1 C11.6666667,1 14.3333333,2.5 17,5.5"></path>
                </symbol>
              </use></svg>
          </div>
        </label></div></button>
      <button class="answerNew borderButtonY"  (mouseout)="checked2 = false" (mouseover)="checked2 = true" (click)="NextQuestion(); addPointTo(1)">Peu important<br>
        <div class="feedback"><label class="sad">
          <input type="radio" value="1" name="feedback" checked={{checked2}}>
          <div>
            <svg class="eye left">
              <use xlink:href="#eye">
                <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 4" id="eye">
                  <path d="M1,1 C1.83333333,2.16666667 2.66666667,2.75 3.5,2.75 C4.33333333,2.75 5.16666667,2.16666667 6,1"></path>
                </symbol>
              </use></svg>
            <svg class="eye right">
              <use xlink:href="#eye">
              </use></svg>
            <svg class="mouth">
              <use xlink:href="#mouth">
                <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 7" id="mouth">
                  <path d="M1,5.5 C3.66666667,2.5 6.33333333,1 9,1 C11.6666667,1 14.3333333,2.5 17,5.5"></path>
                </symbol>
              </use></svg>
          </div>
        </label></div></button>
      <button class="answerNew borderButtonY"  (mouseout)="checked3 = false" (mouseover)="checked3 = true"  (click)="NextQuestion(); addPointTo(2)">Important<br>
        <div class="feedback"><label class="good">
          <input type="radio" value="1" name="feedback" checked={{checked3}}>
          <div>
            <svg class="eye left">
              <use xlink:href="#eye">
                <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 4" id="eye">
                  <path d="M1,1 C1.83333333,2.16666667 2.66666667,2.75 3.5,2.75 C4.33333333,2.75 5.16666667,2.16666667 6,1"></path>
                </symbol>
              </use></svg>
            <svg class="eye right">
              <use xlink:href="#eye">
              </use></svg>
            <svg class="mouth">
              <use xlink:href="#mouth">
                <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 7" id="mouth">
                  <path d="M1,5.5 C3.66666667,2.5 6.33333333,1 9,1 C11.6666667,1 14.3333333,2.5 17,5.5"></path>
                </symbol>
              </use></svg>
          </div>
        </label></div></button>
      <button class="answerNew borderButtonY"  (mouseout)="checked4 = false" (mouseover)="checked4 = true" (click)="NextQuestion(); addPointTo(3)">Très important<br>
        <div class="feedback"><label class="happy">
          <input type="radio" value="1" name="feedback" checked={{checked4}}>
          <div>
            <svg class="eye left">
              <use xlink:href="#eye">
                <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 4" id="eye">
                  <path d="M1,1 C1.83333333,2.16666667 2.66666667,2.75 3.5,2.75 C4.33333333,2.75 5.16666667,2.16666667 6,1"></path>
                </symbol>
              </use></svg>
            <svg class="eye right">
              <use xlink:href="#eye">
              </use></svg>
            <svg class="mouth">
              <use xlink:href="#mouth">
                <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 7" id="mouth">
                  <path d="M1,5.5 C3.66666667,2.5 6.33333333,1 9,1 C11.6666667,1 14.3333333,2.5 17,5.5"></path>
                </symbol>
              </use></svg>
          </div>
        </label></div> </button>

    </div>

  </div>
</div>










<div class="menubas" *ngIf="!isDone">


  <!-- <p style="padding-right: 120px">Votre profil est  : <b>Artistique</b> et   <b>Social</b></p>-->  <div class="container"style="">
  <div class="row" >
    <button class="myButtonL col" [hidden]=isDone (click)="reload()"  >Recommencer le test ?</button>
    <button class="myButtonLG col "*ngIf="RIASECSet === true && loader === true"  [hidden]=isDone >Etape suivante - Affiner mon profil ?</button>
    <button class="myButtonLG col "*ngIf="RIASECSet === false  && prerequisSet ===true  && loader === true"  [hidden]=isDone >Etape suivante - Affiner mon profil ?</button>

    <button class="myButtonL col "*ngIf="RIASECSet === true && loader === false"  [hidden]=isDone (click)="isDone = true; RIASECSet = false; prerequisSet = true ;compteurQuestions = 0; competenceSet = false ">Etape suivante - Affiner mon profil ?</button>

    <button class="myButtonL col" *ngIf="RIASECSet === false  && prerequisSet ===true && loader === false" [hidden]=isDone (click)="isDone = true; RIASECSet = false; compteurQuestions = 0; prerequisSet = false ;competenceSet = true" >Etape suivante - Affiner mon profil ?</button>

    <button class="myButtonL col"   [hidden]=isDone (click)="generatePdf()" > Faire un export de votre profil (.pdf)</button>

  </div>

</div>
</div>

<button class="answer myButton "  *ngIf="RIASECSet === false && debugmod === true" (click)="openDialog(content)"> Voir les points pour les prerequis</button>


<ng-template #content let-c="close" let-d="dismiss" >
  <div class="modal-body px-0">
    <div style="overflow-y: hidden; height: calc(100vh - 15rem);">
      <div class="px-2" style="overflow-y: auto; height: 100%;">
        <div class=" card">
          <div class=" card-header"> Liste des Prerequis
          </div>
   <ul><li  *ngFor="let prerequisite of sortPrerequisite">{{prerequisite.name}} / Point : {{prerequisite.point}}</li></ul>
          </div>
    </div>
  </div>
    <div style="overflow-y: hidden; height: calc(100vh - 15rem);">
      <div class="px-2" style="overflow-y: auto; height: 100%;">
        <div class=" card">
          <div class=" card-header"> Liste des Competences
          </div>
          <ul><li  *ngFor="let competence of sortComptencesListe">{{competence.name}} / Point : {{competence.point}}</li></ul>
        </div>
      </div>
    </div>
  </div>
</ng-template>
</div>


      <div class="our-team">

        <div class="picture"   (click)="Navig(discipline, discipline)">
          <img class="img-fluid"   (click)="Navig(discipline, discipline)">
        <div class="team-content" (click)="Navig(discipline, discipline)">
          <h3 style="font-size:17px;">{{discipline === "Terre" ? "Science et biologie" : discipline}}</h3>

        </div>
      </div>
      </div>


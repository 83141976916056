import {Component, Input, OnInit} from '@angular/core';
import {ApiService, FormationInterface} from '../../../service/api.service';
import {CookieService} from 'ngx-cookie-service';
import {Router} from '@angular/router';

@Component({
  selector: 'presentation',
  templateUrl: './presentation.component.html',
  styleUrls: ['./presentation.component.css']
})
export class PresentationComponent implements OnInit {
  num: any = 0;


  constructor(
    private router: Router,private api: ApiService ,  private cookieService: CookieService ) { }

  ngOnInit(): void {
  }


  next() {
    this.num = this.num +1

    if (this.num === 4){
      this.router.navigate(['/quiz']);
    }
  }
  onSubmit(route: string): void {
    if (this.num !== 3){
      this.next()
    }

  }

  skip(route: string){
    this.router.navigate([route]);
  }
}


<div class="container">
  <div class="row" style="padding-top: 10%" >

    <h1 class="question-label bubble bubble-bottom-left"  >
     <p *ngIf="num === 0" style="padding-top: 10%"> Bienvenue sur le Guide de l'Orientation ELAN</p>
      <p *ngIf="num === 1">Le goELAN permet d'en savoir plus sur les chemins d'orientation qui correspondent aux attentes des futurs étudiants.<br><br>
        En répondant à 3 séries de questions portant sur des intérêts, des compétences développées au lycée, et celles développées à l'université, le GOELAN propose des chemins correspondant le mieux à chaque profil.</p>

      <p *ngIf="num === 2" style="padding-top: 10%"> L'outil se décompose en 3 séries de questions. À chaque série il est possible d'arrêter ou poursuivre le test et obtenir des résultats plus précis!</p>
      <div *ngIf="num === 3" class="row" style="margin-left: 0px">
        <div class="column box" style="background-color:#4b74ff; ">
          <h2>Serie 1</h2>
          <p>L'intérêt professionel</p>
        </div>
        <div class="column box" style="background-color:#9da125;  ">
          <h2>Serie 2</h2>
          <p>Les compétences à développer</p>
        </div>
        <div class="column box" style="background-color:#27861c;  ">
          <h2>Serie 3</h2>
          <p>Les compétences à acquérir</p>
        </div>
      </div>
    </h1>
    <div  class="transition"></div>

    <p style="color: #1C2331 ;
  text-decoration: underline;
cursor: pointer;" *ngIf="num !== 3" (click)="skip('/quiz')" > Passer les explications ?</p>
</div>
  <div style="text-align: center" >
    <button class="answerNew borderButtonB"  (click)="next()" >{{num === 3 ?'Commencer le test' : 'Continuer' }}</button>

 </div>
</div>

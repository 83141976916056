<div > </div>
<div class="container">
  <div class="row" style="padding-top: 10%" >

    <div class="question-label bubble bubble-bottom-left"  >



     <form class="example-form"(ngSubmit)="submit()" novalidate>


       <mat-form-field class="example-full-width" appearance="fill">
         <mat-label> Laisser un commentaire !</mat-label>
         <textarea matInput #message [formControl]="form.controls.name" ></textarea>
       </mat-form-field>
        <button class="btn search-button"  > Envoyer</button>
     </form>
      <button class="btn search-button" (click)="LienQuestion()"  style="margin-top : 20px">Donner un avis sur goELAN</button>
    </div >
    <div   class="mascotte" ></div>

</div>

</div>

<div class="container">
  <div class="">
    <ul class="gallery">

      <h1 class="textcenter" >Parmi ces photos laquelle vous inspirent le plus</h1>
      <li></li>
      <li (click)="NextQ()" *ngIf="display" @inOutPaneAnimation><a  class="pic-2"><img src="https://via.placeholder.com/300"  alt="Photograph of clouds and sunlight" /></a> </li>
      <li (click)="NextQ()" *ngIf="display" @inOutPaneAnimationA><a   class="pic-3"><img src="https://via.placeholder.com/300"  alt="Photograph of a lake scene at dusk" /></a></li>
      <li (click)="NextQ()" *ngIf="display" @inOutPaneAnimationB><a  class="pic-4"><img src="https://via.placeholder.com/300"  alt="Photograph of a tree and green grass" /></a></li>
 </ul>
</div>
</div>

import {Component, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup} from '@angular/forms';
import {FormationInterface} from '../../../service/api.service';
import {MatDialog} from '@angular/material/dialog';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-accueil',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  animations: [
  trigger(
    'inOutAnimation',
    [
      transition(
        ':enter',
        [
          style({ width: 0, opacity: 0 }),
          animate('0.1s linear',
            style({ width: '40%', opacity: 1 }))
        ]
      ),
    ]
  )
]
})
export class HomeComponent implements OnInit {
  data: FormationInterface[];
  formVar: FormGroup;
  open: boolean = false;
  text: string;
  DialogBoxL: boolean = false;
  DialogBoxR: boolean;

  constructor(public dialog: MatDialog,
              private router: Router,
              private fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.formVar = this.fb.group({
      searchBar: ''
    });
    this.open = true;
  }

  getBySearch(): void {
    this.text = this.formVar.value.searchBar;
    this.router.navigate(['formation/' + this.text]);
  }
  onSubmit(route: string): void {
    this.router.navigate([route]);
  }

    openDialog(): void {
      const dialogRef = this.dialog.open(DialogContentExampleDialog);

      dialogRef.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);
      });
    }

}
@Component({
    selector: 'dialog-use',
    templateUrl: 'dialog-use.html'
})
  export class DialogContentExampleDialog {}



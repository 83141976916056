import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ApiService, CompetenceInterface } from '../../../../service/api.service';
import { async } from '@angular/core/testing';
@Component({
  selector: 'app-add-formation',
  templateUrl: './add-formation.component.html',
  styleUrls: ['./add-formation.component.css']
})
export class AddFormationComponent implements OnInit {

  data: CompetenceInterface[];
  addFormation: FormGroup;
  name;
  description;
  prerequise;
  contact;
  job;
  competence: CompetenceInterface[];
  compteur: number ;
  recherche;

  constructor(private fb: FormBuilder,
              private http: HttpClient,
              private api: ApiService) { }

  ngOnInit(): void {
    this.compteur = 1;
    this.getInfo();
    this.addFormation = this.fb.group({
      name: '',
      description: '',
      prerequise: '',
      contact: '',
      job: '',
      competence: [],
      search: ''
    });

  }

  submitForm() {
    this.name = this.addFormation.value.name;
    this.description = this.addFormation.value.description;
    this.prerequise = this.addFormation.value.prerequise;
    this.contact = this.addFormation.value.contact;
    this.job = this.addFormation.value.job;
    this.competence = this.addFormation.value.competence;


    console.log(this.addFormation.value.competence);
    this.api.postFormation(this.name, this.description, this.prerequise, this.contact, this.job, this.addFormation.value.competence)
      .toPromise().then((form) => {
      console.log(form);
    }).catch((err) => {
      console.log(err);
    });

  }




  getInfo()
  {
    this.api.getCompetence().toPromise().then(
      (formation) => {
        this.data = formation;
      });

  }

  getCompetenceBySearch() {
    this.recherche = this.addFormation.value.search;

    this.api.getCompetenceBySearchText(this.recherche).toPromise().then((formation) => {
      this.data = formation;
    });
    if (this.data.length === 0) {
      alert('aucune compétence trouvé pour la recherche : ' + this.recherche);
    }
  }

}

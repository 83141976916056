import { DetailComponent } from './component/detail/detail.component';
import { BrowserModule } from '@angular/platform-browser';
import {NgModule, OnInit} from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormationComponent } from './component/formation/formation.component';
import { ListFormationComponent } from './component/list-formation/list-formation.component';
import { MatSliderModule } from '@angular/material/slider';
import { SearchComponent } from './component/search/search.component';
import { QuestionComponent } from './component/question/question.component';
import {DialogContentExampleDialog, HomeComponent} from './component/home/dialog-content-example-dialog.component';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { HttpClientModule } from '@angular/common/http';
import { NavbarComponent } from './component/navbar/navbar.component';
import { ProfilComponent } from './component/profil/profil.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PolarChartModule} from '@swimlane/ngx-charts';
import { AdminHomeComponent } from './component/admin/admin-home/admin-home.component';
import { AddFormationComponent } from './component/admin/add-formation/add-formation.component';
import { AddCompetenceComponent } from './component/admin/add-competence/add-competence.component';
import {MatSelectModule} from '@angular/material/select';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatExpansionModule} from '@angular/material/expansion';
import { CookieService } from 'ngx-cookie-service';
import { FavoriteComponent } from './component/favorite/favorite.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {LoginComponent} from './component/admin/login/login.component';
import { AddUserComponent } from './component/admin/add-user/add-user.component';
import { MyprofilComponent } from './component/myprofil/myprofil.component';
import {MatDialogModule} from '@angular/material/dialog';
import { DebugComponent } from './component/debug/debug.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import { PresentationComponent } from './component/presentation/presentation.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import {CommentaireComponent} from './component/commentaire/commentaire.component';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {orientationComponent} from './component/orientation/orientation.component';
import {NgCircleProgressModule} from 'ng-circle-progress';
import {testFrontComponent} from './component/testfront/testfront.component';
import {ListDisciplineComponent} from './component/list-discipline/list-discipline.component';

@NgModule({
    declarations: [
        AppComponent,
        FormationComponent,
        ListFormationComponent,
        HomeComponent,
        SearchComponent,
        NavbarComponent,
        DetailComponent,
        QuestionComponent,
        ProfilComponent,
        AdminHomeComponent,
        CommentaireComponent,
        AddFormationComponent,
        AddCompetenceComponent,
        FavoriteComponent,
        LoginComponent,
        AddUserComponent,
        MyprofilComponent,
        DialogContentExampleDialog,
        DebugComponent,
        PresentationComponent,
        orientationComponent,
        testFrontComponent,
        ListDisciplineComponent
    ],
    imports: [
        NgCircleProgressModule.forRoot({}),
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MatCardModule,
        MatButtonModule,
        MatIconModule,
        MatSliderModule,
        MatSidenavModule,
        HttpClientModule,
        ReactiveFormsModule,
        PolarChartModule,
        MatSelectModule,
        MatExpansionModule,
        MatSnackBarModule,
        MatProgressBarModule,
        FormsModule,
        MatDialogModule,
        MatInputModule,
        MatProgressSpinnerModule
    ],
  providers: [CookieService,{provide: LocationStrategy, useClass: HashLocationStrategy}] ,
  bootstrap: [AppComponent],
})
export class AppModule{

}

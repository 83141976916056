
<div class="container">
  <div class="row" class="pad">
    <h1>Liste des formations favorites</h1>
    <button class="myButton" *ngIf="cookieValue.length !== 0" (click)="deletFav()" >Supprimer tous mes favoris </button>
  </div>
  <div class="row" style="margin-top: 30px">
    <h1 *ngIf="cookieValue.length === 0">Vous n’avez aucun favoris, sélectionnez une formation et cliquez sur   <mat-icon>favorite</mat-icon> pour l’ajouter à votre liste de favoris</h1>
    <div class="container">
<app-list-formation *ngFor="let formation of data, index as i" class=" " [formationDesctiption]="data[i].description" [formationId]="data[i].id" [formationName]="data[i].name"  [formationDiscipline]="data[i].discipline" [formationAvatar]="data[i].avatar " ></app-list-formation>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {ApiService} from "../../../../service/api.service";
import {Router} from "@angular/router";
import {AuthService} from "../../../../service/auth.service";
import {CookieService} from 'ngx-cookie-service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})


export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  email;
  password;

  constructor(private api: ApiService,
              private fb: FormBuilder,
              private router: Router,
              private authService: AuthService,
              private cookieService : CookieService) { }

  ngOnInit() {
    this.loginForm = this.fb.group({
      email: '',
      password: ''
    });
  }

  redirectCreateUser(){
    this.router.navigate(['/add-user']);
  }

  login() {
    this.email = this.loginForm.value.email;
    this.password = this.loginForm.value.password;



    this.api.getUser(this.email, this.password).toPromise().then((data: []) => {
      console.error(data)
        if (data.length === 0) {
          alert("Merci de réessayer l'email ou le mot de passe sont incorrect");
        }
        if (data.length > 0) {
          // @ts-ignore
          if ('admin' === data[0].role){
            this.authService.isAuth = true

          }
          this.authService.isLog = true;
          this.router.navigate(['/home']);
        }
    });
  }

}

import {Component, OnInit} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {ChildrenOutletContexts} from '@angular/router';
import {slideInAnimation} from './animation';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [
    slideInAnimation
  ]
})
export class AppComponent  implements OnInit{
   pink: boolean

  constructor( private cookieService: CookieService , private contexts: ChildrenOutletContexts) {}

  getRouteAnimationData() {
    return this.contexts.getContext('primary')?.route?.snapshot?.data?.['animation'];
  }

  ngOnInit(): void {
    console.log(this.cookieService.get('mode'))
    if(this.cookieService.get('mode') === 'pink' ){
      this.pink = true
    }

  }
  title = 'ELAN';

  LienElan() {
    window.open('https://www.elan.uha.fr/');

  }
}

import { Component, OnInit } from '@angular/core';
import {ApiService, FormationInterface} from '../../../service/api.service';
import {Router} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';
import {element} from 'protractor';

@Component({
  selector: 'app-favorite',
  templateUrl: './favorite.component.html',
  styleUrls: ['./favorite.component.css']
})
export class FavoriteComponent implements OnInit {

  data: FormationInterface[];
  public cookieValue: string[];

  constructor(private router: Router , private cookieService: CookieService , private api: ApiService, ) {

  }

  ngOnInit(): void {
  this.data = [];
  this.cookieValue = Object.keys(this.cookieService.getAll());
  this.getApi();
  }

  async getApi(): Promise<any> {
    this.cookieValue.forEach(element => this.api.getFormationById(element).toPromise().then(formation => this.data.push(formation)));
  }

  deletFav(): void {
    this.cookieService.deleteAll();
    window.location.reload();
  }
}

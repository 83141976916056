<div class="loader" *ngIf="loader === true" xmlns="http://www.w3.org/1999/html"> <div style="
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
margin-top: 20%"><div style="
  width: 200px;
  height: 200px;">
  <div class="load"></div>
</div></div>
</div>


<mat-drawer-container   autosize   style="position: absolute;right: 0px;left: 0px;bottom: 0px;top: 0px;">

  <mat-drawer #drawer class="sizedrawer" mode="side">
    <div class="container">
      <div class="row padding" id="myUL">
<div class="input-group mb-3" style="margin-top: 20%">
  <mat-icon>search</mat-icon> <input type="text" class="col form-control"  id="myInput" (keyup)="Tri()" placeholder="Chercher une disipline" title="T">
</div><br>

          <button class="boutonNav" (click)="getByLimit()"><a>Tous</a></button>
          <button class="boutonNav" (click)="SortByDis('Agronomie' ,'Agronomie, agroalimentaire, nutrition', page)"><a>	Agronomie, agroalimentaire, nutrition </a></button>
          <button class="boutonNav" (click)="SortByDis('Arts','Arts et design' , page ) "><a>Arts et design</a></button>
          <button class="boutonNav" (click)="SortByDis('Communication','Communication, information, journalisme' , page ) "><a>Communication, information, journalisme</a></button>
          <button class="boutonNav" (click)="SortByDis('Documentation','Documentation, archives' , page ) "><a>Documentation, archives</a></button>
          <button class="boutonNav" (click)="SortByDis('Droit' ,'Droit, administration publique, propriété intellectuelle et industrielle', page ) "><a>Droit, administration publique, propriété intellectuelle et industrielle</a></button>
          <button class="boutonNav" (click)="SortByDis('Economie' ,'Economie', page ) "><a>Economie	</a></button>
          <button class="boutonNav" (click)="SortByDis('Education' ,'Education, formation des enseignants', page ) "><a> Education, formation des enseignants </a></button>
          <button class="boutonNav" (click)="SortByDis('Géographie' ,'Géographie, aménagement', page ) "><a>Géographie, aménagement</a></button>
          <button class="boutonNav" (click)="SortByDis('Histoire' ,'Histoire', page) "><a>Histoire</a></button>
          <button class="boutonNav" (click)="SortByDis('Ingénierie' ,'Ingénierie et technologies', page ) "><a>Ingénierie et technologies</a></button>
          <button class="boutonNav" (click)="SortByDis('Lettres' ,'Lettres, langues, sciences du langage', page) "><a>Lettres, langues, sciences du langage</a></button>
          <button class="boutonNav" (click)="SortByDis('Mathématiques','Mathématiques et informatique', page) "><a>Mathématiques et informatique</a></button>
          <button class="boutonNav" (click)="SortByDis('Philosophie','Philosophie, éthique', page) "><a>Philosophie, éthique</a></button>
          <button class="boutonNav" (click)="SortByDis('Physique','Physique, chimie, matériaux', page) "><a>Physique, chimie, matériaux</a></button>
          <button class="boutonNav" (click)="SortByDis('Psychologie','Psychologie, sociologie, démographie, ethnographie', page) "><a>Psychologie, sociologie, démographie, ethnographie</a></button>
          <button class="boutonNav" (click)="SortByDis('Relations','Relations internationales, études européennes, études régionales', page) "><a>Relations internationales, études européennes, études régionales</a></button>
          <button class="boutonNav" (click)="SortByDis('Terre','Sciences de la terre et de l\'univers, environnement', page) "><a>Sciences de la terre et de l'univers, environnement</a></button>
          <button class="boutonNav" (click)="SortByDis('Vie','Sciences de la vie et de la santé, psychologie', page) "><a>Sciences de la vie et de la santé, psychologie</a></button>
          <button class="boutonNav" (click)="SortByDis('Sport','Sciences du sport', page) "><a> Sciences du sport </a></button>
          <button class="boutonNav" (click)="SortByDis('Politiques', 'Sciences politiques',page) "><a>Sciences politiques</a></button>
          <button class="boutonNav" (click)="SortByDis('Théologie','Théologie', page) "><a>Théologie</a></button>
          <button class="boutonNav" (click)="SortByDis('Urbanisme','Urbanisme, architecture', page) "><a>Urbanisme, architecture</a></button>

      </div>
    </div>

  </mat-drawer>

 <!-- <button class="pulse-button" [ngClass]="{'noactive':!active , 'activenav' :active }"type="button" (click)="drawer.toggle() ; active=!active">
    <mat-icon style="margin-top: 3px">chevron_right</mat-icon>
  </button>-->


<div class="">
  <div class=" container padding" >  <div class="example-sidenav-content">

  </div>
    <h1 style="background-color: #EFF6FF">{{textFormation}}</h1>
  </div><div class="row " style="margin-left: 0px; margin-right: 0px">
  <div class=" container" style="padding-bottom: 70px ;padding-top : 50px;
  border-radius: 25px; background-color: rgba(0,150,255,0)">

    <app-list-formation *ngFor="let formation of data, index as i" class=" " [formationId]="data[i].id" [formationName]="data[i].name" [formationDesctiption]="data[i].description" [formationAvatar]="data[i].avatar" [formationDiscipline]="data[i].discipline"></app-list-formation>
  </div></div>
<div class="positionPagination">
  <ul class="pagination pag" style=" ">
    <li><button  class="rondedButton" [disabled]="page <= 1"  (click)="previousPage()" ><mat-icon>navigate_before</mat-icon></button></li>
    <li class="pageNumber" [ngClass]="page === 1 ? 'active' : ''"><a (click)="navigate(1)">{{page >= 3 ? page - 2 : "1"}}</a></li>
    <li class="pageNumber" [ngClass]="page === 2 ? 'active' : ''"><a (click)="navigate(2)">{{page >= 3 ? page - 1 : "2"}}</a></li>
    <li class="pageNumber" [ngClass]="page >= 3 ? 'active' : ''"><a (click)="navigate(3)">{{page >= 3 ? page: "3"}}</a></li>
    <li class="pageNumber" [ngClass]="page === 4 ? ' ' : ''"><a (click)="navigate(4)">{{page >= 3 ? page + 1 : "4"}}</a></li>
    <li class="pageNumber"[ngClass]="page === 5 ? ' ' : ''"><a (click)="navigate(5)">{{page >= 3 ? page + 2 : "5"}}</a></li>
    <li><button class="rondedButton" (click)="nextPage()"><mat-icon color="black">navigate_next</mat-icon> </button></li>
  </ul>
</div>
</div>

</mat-drawer-container>

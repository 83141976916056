import {Component, HostListener, OnInit} from '@angular/core';
import {AuthService} from "../../../service/auth.service";
import {FormationInterface} from '../../../service/api.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  data: FormationInterface[];
  formVar: FormGroup;


  text: string;
  scroll:boolean=false;
  admin = false;
  trigerNav: boolean

  constructor(public authService: AuthService,
  private router: Router,
  private fb: FormBuilder,) { }

  ngOnInit(): void {
    this.formVar = this.fb.group({
      searchBar: '',
    });
    this.trigerNav = false
    window.addEventListener('scroll', this.scrolling, true)
  }

  openNav(){
    document.getElementById('mySidenav').style.width = '280px';
  }

  closeNav(){
    document.getElementById('mySidenav').style.width = '0';
  }

  getBySearch() {

    this.text = this.formVar.value.searchBar;
    this.router.navigate(['formation/' + this.text]);

    setTimeout (function reload() {
      window.location.reload();
    },220)


  }
  onSubmit(route: string) {
    this.router.navigate([route]);
  }


  @HostListener('scroll', ['$event'])  onScroll($event: Event): void {
    console.log($event);
  };

  scrolling=(s)=>{
    if (s.explicitOriginalTarget.scrollTop > 100) {
      this.scroll = true
    }
    else {
      this.scroll = false
    }
  }

}

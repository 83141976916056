

<div class="user-profil">
   <!-- <div class="profil-success">
     <span>Merci beaucoup !</span><br/>
     <span>Les réponses que vous avez apportées au questionnaire nous on permit de mieux connaître les deux facettes prédominantes de votre profil !</span><br/>
     <b>Voir les formations <div class="bounce"><br/>
       <a href="#formation"><img width="40" height="40" alt="" src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDMyIDMyIiBoZWlnaHQ9IjMycHgiIGlkPSLQodC70L7QuV8xIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAzMiAzMiIgd2lkdGg9IjMycHgiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxwYXRoIGQ9Ik0yNC4yODUsMTEuMjg0TDE2LDE5LjU3MWwtOC4yODUtOC4yODhjLTAuMzk1LTAuMzk1LTEuMDM0LTAuMzk1LTEuNDI5LDAgIGMtMC4zOTQsMC4zOTUtMC4zOTQsMS4wMzUsMCwxLjQzbDguOTk5LDkuMDAybDAsMGwwLDBjMC4zOTQsMC4zOTUsMS4wMzQsMC4zOTUsMS40MjgsMGw4Ljk5OS05LjAwMiAgYzAuMzk0LTAuMzk1LDAuMzk0LTEuMDM2LDAtMS40MzFDMjUuMzE5LDEwLjg4OSwyNC42NzksMTAuODg5LDI0LjI4NSwxMS4yODR6IiBmaWxsPSIjMTIxMzEzIiBpZD0iRXhwYW5kX01vcmUiLz48Zy8+PGcvPjxnLz48Zy8+PGcvPjxnLz48L3N2Zz4=" /></a>
     </div></b>
   </div> -->
  </div>
<mat-expansion-panel *ngIf=debugmod (opened)="panelOpenState = true"
                     (closed)="panelOpenState = false">
  <mat-expansion-panel-header>
    <mat-panel-title>
     Liste des competences
    </mat-panel-title>
    <mat-panel-description>
    </mat-panel-description>
  </mat-expansion-panel-header><ul>
  <li *ngFor="let comp of comp">{{ comp.name }} {{ comp.profile }}</li>
  <li style="color: red" *ngFor="let comp of newComp">{{ comp.name }} {{ comp.profile }}</li>
</ul>
</mat-expansion-panel>
<mat-expansion-panel *ngIf=debugmod (opened)="panelOpenState = true"
                     (closed)="panelOpenState = false">
  <mat-expansion-panel-header>
    <mat-panel-title>
      Liste des questions
    </mat-panel-title>
    <mat-panel-description>
    </mat-panel-description>
  </mat-expansion-panel-header><ul>
  <li *ngFor="let question of QuestionCompetenceArray">{{ question.name }}/ {{ question.id}} / </li>
  <li style="color: red" *ngFor="let comp of questionComp">{{ comp.name }} {{ comp.id }}</li>
</ul>
  <ul>
    <li *ngFor="let question of QuestionPrerequiseArray">{{ question.name }} / {{ question.id}}</li>
  </ul>
 </mat-expansion-panel>
 <div class="container" style="padding-top: 35px" >
 <button type="button" class="btn btn-primary" [ngClass]="viewDiscipline ? 'disabled' : ''" (click)="viewDiscipline ? viewDiscipline = !viewDiscipline : null">Voir les disciplines</button>
 <button type="button" class="btn btn-primary" [ngClass]="viewDiscipline ? '' : 'disabled'" (click)="!viewDiscipline ? viewDiscipline = !viewDiscipline : null">Voir les formations</button>
 </div>
 <!-- <h1>Votre type de profil est plutôt <b>{{profilTitleOne}}</b> et <b>{{profilTitleTwo}}</b></h1> -->
 <div class="" style="padding-bottom: 10%">
   <div class="container" *ngIf="viewDiscipline" style="padding-top: 5%">

    <!--   <button (click)="consolelog()">AAAAAAAAAAAAAAAAA</button> -->

    <app-list-formation id="formation "*ngFor="let formation of FormationArray, index as i" [formationId]="FormationArray[i].id" [formationName]="FormationArray[i].name" [formationDesctiption]="FormationArray[i].description" [formationAvatar]="FormationArray[i].avatar" [formationDiscipline]="FormationArray[i].discipline" [formationScore]="FormationArray[i].point"></app-list-formation>
  </div>
</div>
    <div class="container" style="padding-top: 5%" *ngIf="!viewDiscipline">
      <div class="row" style="padding-bottom: 10%">
      <app-discipline-formation class="col-6  col-lg-3"  id="discipline" *ngFor="let discipline of discipline, index as i"
                        [discipline]="discipline"></app-discipline-formation>
    </div>
  </div>
 <!-- a modifier chart rond-->
 <div *ngIf="!open" class="footerBar">
   <button class="rondedbutton" (click)="open = !open">X</button>
   <div style="padding-left: 15%" >
     <ngx-charts-polar-chart
       class="charts"
       [view]="view"
       [scheme]="colorScheme"
       [showXAxisLabel]="showXAxisLabel"
       [showYAxisLabel]="showYAxisLabel"
       [xAxis]="xAxis"
       [results]="multi" >
     </ngx-charts-polar-chart></div>

 </div>

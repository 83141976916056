<div class="row-cols-2" style="text-align: center; padding-left: 30%; padding-top: 5% ">
  <form class="form-group" [formGroup]="addFormation">
    <h2>Nom de la Formation :</h2>
    <input class="form-control" type="text" formControlName="name" id="name" placeholder="Nom de la compétence">

    <br>
    <h2>Description :</h2>
    <input class="form-control" type="text" formControlName="description" id="description" placeholder="Description">
    <br>
    <h2>Prérequis :</h2>
    <input class="form-control" type="text" formControlName="prerequise" id="prerequise" placeholder="Prérequis ">
    <br>
    <h2>Contact :</h2>
    <input class="form-control" type="text" formControlName="contact" id="contact" placeholder="Contact">
    <br>
    <h2>Job possible  :</h2>
    <input class="form-control" type="text" formControlName="job" id="job" placeholder="Job">

    <h2>Compétence à obtenir </h2>


    <br>
    <div>
      <input type="text" formControlName="search" class="form-control">
      <button class="btn-primary btn" (click)="getCompetenceBySearch()">Search</button>
    </div>
    <mat-form-field class="col-lg-12">
      <mat-select class="form-control" multiple formControlName="competence" id="competence">
        <mat-option *ngFor="let comp of  data,index as i " [value]="comp">{{data[i].name}}</mat-option>
      </mat-select>
    </mat-form-field>

    <br>
    <button class="col-lg-12 btn btn-secondary" (click)="this.submitForm()">Ajouter</button>
  </form>
</div>

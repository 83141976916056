import {Component, Input, NgModule, OnInit} from '@angular/core';
import {ApiService, FormationInterface} from '../../../service/api.service';
import {CookieService} from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import { NgCircleProgressModule } from 'ng-circle-progress';





@Component({
  selector: 'orientationComponent',
  templateUrl: './orientation.component.html',
  styleUrls: ['./orientation.component.css']
})
export class orientationComponent implements OnInit{
  progress = 0;
  num: any = 0;
  screenSize = screen.width
  hiddenDrawer = false

  constructor(
    private _snackBar: MatSnackBar , private router: Router,private api: ApiService ,  private cookieService: CookieService ) { }
    form = new FormGroup({
    name: new FormControl('' )
  });

  progressBar = document.querySelector('.progress-bar');
  intervalId;
  ngOnInit(): void {


  }


  Navig(value , text) {
    if (value === 'profetes'){
      window.open('https://www.google.com/url?sa=t&rct=j&q=&esrc=s&source=web&cd=&ved=2ahUKEwjKqrfq6671AhXK7rsIHXt0AX4QFnoECAYQAQ&url=https%3A%2F%2Fwww.uha.fr%2F_resources%2FFormation%2FChamps%2520de%2520formation%2520%26%2520dipl%25C3%25B4mes%2Fplaquette-offre-de-formation-uha.pdf&usg=AOvVaw14hOb2bG-yvIAwdnKMP7uK');
    }else{
    this.router.navigateByUrl('/formation', { state: { value:value , text:text } });
  }
    if (value === ''){
      this.router.navigateByUrl('/formation')
    }
  }

}


<div class="row-cols-2" style="text-align: center; padding-left: 30%; padding-top: 10% ">

  <h1 style="text-align: center">Créer mon compte !</h1>
  <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
    <h2>Email:</h2>
    <input type="text" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
      <div *ngIf="f.email.errors.required">Email est requis</div>
      <div *ngIf="f.email.errors.email">Email doit etre valide</div>
    </div>

    <br>
    <h2>Mot de passe :</h2>
    <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
      <div *ngIf="f.password.errors.required">Mot de passe est requis</div>
      <div *ngIf="f.password.errors.minlength">Le mot de passe doit faire plus de 6 caractere </div>
    </div>
    <br>
    <h2>Confirmer Mot de passe :</h2>
    <input type="password" formControlName="confirmPassword" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" />
    <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
      <div *ngIf="f.confirmPassword.errors.required">Vous devez confirmer le mot de passe</div>
      <div *ngIf="f.confirmPassword.errors.mustMatch">Le mot de passe doit être le meme </div>
    </div>
    <br>
    <div class="form-group form-check">
      <input type="checkbox" formControlName="acceptTerms" id="acceptTerms" class="form-check-input" [ngClass]="{ 'is-invalid': submitted && f.acceptTerms.errors }" />
      <label for="acceptTerms" class="form-check-label">Accepter les Terms & Conditions</label>
      <div *ngIf="submitted && f.acceptTerms.errors" class="invalid-feedback">Vous devez accepter les Terms & Conditions</div>
    </div>
    <button class="btn btn-primary mr-1">Register</button>
  </form>
</div>

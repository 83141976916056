<div class="background"*ngIf="data">
  <h1>{{data.name}}</h1>
  <div class="divplusbas">


    <div class="col-12">
      <h2 class="predepla">Description</h2>
      <p>{{data.description}}</p>
    </div>
  </div>


  <div class="col-12 mx-auto" >

    <h2 class="predepla">Prérequis</h2>
    <p>{{data.prerequisite}}</p>



  </div>

  <div class="col-12">
    <h2>Compétence: </h2>
    <p *ngFor="let formation of data.competences, index as i" > {{formation.name }}</p>

    <h2 class="front-inline">Contact </h2>
    <p class="front-inline">
      {{data.contact}}
    </p>
    <div>
    </div>
  </div>
</div>

import {Component, Input, OnInit} from '@angular/core';
import {ApiService} from '../../../service/api.service';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';

@Component({
  selector: 'app-list-formation',
  templateUrl: './list-formation.component.html',
  styleUrls: ['./list-formation.component.css']
})
export class ListFormationComponent implements OnInit {

  @Input() formationName: string ;
  @Input()  formationId: number;
  @Input() formationDesctiption : string;
  @Input() formationAvatar : string;
  @Input() formationDiscipline : string;
  @Input() formationScore : number
  isFavorite: boolean;
  hiddeScore: boolean;


  constructor(private router: Router , private cookieService: CookieService ) { }

  ngOnInit(): void {

    if (this.formationScore === undefined){
      this.formationScore = 0
      this.hiddeScore = true
    }

    if (this.cookieService.get(String(this.formationId)) !== '')
    {
      this.isFavorite = true
    }
  }

  onSubmit() {
    window.open(this.formationDesctiption);
  }

  like() {
    this.isFavorite = !this.isFavorite

    if(this.isFavorite === true){
      this.cookieService.set(String(this.formationId), this.formationName);
      console.log("Set Test Cookie as Test");

    }
    if(this.isFavorite === false){
      this.cookieService.delete(String(this.formationId), this.formationName);
      console.log("Set Test Cookie as Test");

    }
  }
}

import {Component, HostListener, OnInit} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';

@Component({
  selector: 'app-debug',
  templateUrl: './debug.component.html',
  styleUrls: ['./debug.component.css']
})
export class DebugComponent implements OnInit {
   debugmod: boolean = false;

  constructor(private cookieService: CookieService) { }

  ngOnInit(): void {
    if (this.cookieService.get(String('debugmod')) === 'true')
    {
      this.debugmod = true;
    }
  }
  public keypressed;
  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
   this.keypressed = event.key;

    if (this.keypressed === '$' && this.debugmod !== true){
      this.cookieService.set(String('debugmod'), 'true');
    }
      if (this.keypressed === '$' && this.debugmod === true){
        this.cookieService.delete(String('debugmod'));
      }
  }

}

<div class="width"  [ngClass]="pink ? 'pinkMod' : '' "  [@routeAnimations]="getRouteAnimationData()" >
    <app-navbar></app-navbar>
  <app-debug></app-debug>
  <div>
    <div style="position: fixed; bottom: 0; height: 7%;;width: 100%; z-index: 100; background-color: white">
  <div class="footerL">
  <img  (click)="LienElan()" src="assets/img/2GOelan-TEST-02-02.png" style="cursor: pointer;width: 75%;">
  </div>
    <div class="footerR">
      <img  (click)="LienElan()" src="assets/img/logo-uha.svg" style="cursor: pointer;">
    </div></div></div>
  <router-outlet></router-outlet>
</div>



export let questionsCompetences = [
  { id: 1, name: 'compréhension de texte', point: 0},
  { id: 2, name: 'argumentation,construction d\'un raisonnement', point: 0},
  { id: 3, name: 'culture générale', point: 0},
  { id: 4, name: 'expertise en langue générale', point: 0},
  { id: 5, name: 'maîtrise rédactionnelle', point: 0},
  { id: 6, name: 'compréhension de texte', point: 0},
  { id: 7, name: 'argumentation,construction d\'un raisonnement', point: 0},
  { id: 8, name: 'culture générale', point: 0},
  { id: 9, name: 'expertise en langue générale', point: 0},
  { id: 10, name: 'maîtrise rédactionnelle', point: 0},
  { id: 11, name: 'compréhension de texte', point: 0},
  { id: 12, name: 'argumentation,construction d\'un raisonnement', point: 0},
  { id: 13, name: 'culture générale', point: 0},
  { id: 14, name: 'expertise en langue générale', point: 0},
  { id: 15, name: 'maîtrise rédactionnelle', point: 0},
  { id: 16, name: 'compréhension de texte', point: 0},
  { id: 17, name: 'argumentation,construction d\'un raisonnement', point: 0},
  { id: 18, name: 'culture générale', point: 0},
  { id: 19, name: 'expertise en langue générale', point: 0},
  { id: 20, name: 'maîtrise rédactionnelle', point: 0},
];


<!-- <img class="banner parallax " style="width: 1480px ;" src="../../../assets/img/plaquette-offre-de-formation-uha-1.jpg">
 <img class="banner" src="https://via.placeholder.com/1480x300.png"> -->
<div class="width">
  <div class="container whiteBox" >
    <div style="background-color: whitesmoke">
      <h1 style="text-align: center; padding-top: 5% ; padding-bottom: 5% ; margin-top: 5%;"(click)="Navig('profetes', 'Lettres, langues, sciences du langage')">OFFRE DE FORMATION UHA PAR DOMAINE</h1>

    </div>
    <div class="row">
        <mat-card (click)="Navig('Lettres', 'Lettres, langues, sciences du langage')" class="example-card col-xs-6 col-sm-4">
          <img  mat-card-image src="../../../assets/img/illberg-10.jpg" />
          <mat-card-content class="textCard">INTERCULTURALITÉ </mat-card-content>
        </mat-card>
      <mat-card (click)="Navig('Droit', 'Droit, administration publique, propriété intellectuelle et industrielle')" class="example-card col-xs-6 col-sm-4">
      <img  mat-card-image src="../../../assets/img/fonderie-7.jpg" />
          <mat-card-content class="textCard"> DROIT POLITIQUE et SOCIÉTÉ </mat-card-content>
        </mat-card>
      <mat-card (click)="Navig('Economie', 'Economie')" class="example-card col-xs-6 col-sm-4">
        <img  mat-card-image src="../../../assets/img/fonderie-5.jpg" />
          <mat-card-content class="textCard"> GESTION </mat-card-content>
        </mat-card>


          <mat-card (click)="Navig('Mathématiques', 'Mathématiques et informatique')" class="example-card col-xs-6 col-sm-4">
            <img  mat-card-image src="../../../assets/img/collines-1.jpg" />
            <mat-card-content class="textCard"> NUMÉRIQUE ET INDUSTRIE DU FUTUR </mat-card-content>
          </mat-card>
          <mat-card (click)="Navig('Ingénierie', 'Ingénierie et technologies')" class="example-card col-xs-6 col-sm-4">
            <img  mat-card-image src="../../../assets/img/illberg-16.jpg">
            <mat-card-content class="textCard">INGÉNIERIE ET TECHNOLOGIES </mat-card-content>
          </mat-card>
          <mat-card (click)="Navig('Terre', 'Sciences de la terre et de l\'univers, environnement')" class="example-card col-xs-6 col-sm-4">
            <img  mat-card-image src="../../../assets/img/biopole-1.jpg"/>
            <mat-card-content class="textCard"> SCIENCES ET DÉVELOPPEMENT DURABLE </mat-card-content>
          </mat-card>

        </div>

<!--    <div style="background-color: whitesmoke">

      <h1 style="text-align: center; padding-top: 5% ; padding-bottom: 5% ; margin-top: 5%;">SCHÉMA DE L'ENSEIGNEMENT SUPÉRIEUR</h1>
    </div>
    <img class="banner" src="../../../assets/img/img.png" alt="SCHEMA Superieur">-->

  </div>
</div>

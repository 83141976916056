<h2 mat-dialog-title>Le test d'orientation</h2>
<mat-dialog-content class="mat-typography">
  <h3>Comment fonctionne le test</h3>
  <p> Le test d'orientation est une succession de question globale qui permettra de déterminer un profil RIASEC, suite à se profile vous aurez une liste de formations de l'Uha qui vous correspond
    <br>  Vous pouvez ensuite affiner vos recherches, soit en continuant à définir votre profile riasec, soit en vous positionnant sur des compétences qui correspondent à votre profil</p>




</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Fermer</button>
</mat-dialog-actions>

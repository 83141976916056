<!--

      <div class="our-team">

        <div class="picture" (click)="onSubmit()">
          <img class="img-fluid"   src="{{formationAvatar}}"  (click)="onSubmit()">
&lt;!&ndash;          <p *ngIf="!hiddeScore">Taux d'affinité : {{formationScore.toFixed(2)}}</p>&ndash;&gt;
        </div>
        <div class="team-content"  (click)="onSubmit()">
          <h3 style="font-size:17px;">{{formationName}}</h3>

        </div>
        <div class="buttonBox" >
        <button mat-icon-button (click)="like()" [ngClass]="{'dislike': !isFavorite,'like': isFavorite}"  style=" margin-left: 170px;" aria-label="button with a heart icon">
        <mat-icon>favorite</mat-icon>
        </button> </div>
      </div>
-->



      <div class="card col-md-12 p-3">
        <div class="row " style="margin-left: 0px; margin-right: 0px">
          <div class="col-md-4">
            <img class="w-50" src="{{formationAvatar}}" (click)="onSubmit()">
          </div>
          <div class="col-md-8">
            <div class="card-block">
              <p class="card-text text-justify">{{formationName}}</p>
              <a  class="btn btn-primary" style="color: whitesmoke" (click)="onSubmit()">En savoir plus ?</a>
            </div>
            <div class="buttonBox bottomright" >
              <button mat-icon-button style=" margin-left: 170px;"  (click)="like()" [ngClass]="{'dislike': !isFavorite,'like': isFavorite}" aria-label="button with a heart icon">
                <mat-icon>favorite</mat-icon>
              </button> </div>
          </div>
        </div>
      </div>


     <!-- <form class="form-style" [formGroup]="formVar">
        <div >
          <div class="input-group mb-3">
            <input (keyup.enter)="getBySearch()" class="form-control" type="text" id=" " style="height: 30px" formControlName="searchBar" placeholder="Formation...">
            <div class="input-group-append">
              <button class="btn" style="background-color : #556aea  ; height: 30px;padding-top: 1px" type="button" (click)="getBySearch()"  >Rechercher </button>
            </div>
          </div></div>

      </form> -->


<nav class="navCustome " [ngClass]="scroll ? 'affix' : ''">
  <div class="containercustome">
    <div class="logo">
      <!--     <img class="logoGOelan" src="https://www.elan.uha.fr/wp-content/uploads/2022/01/GOELAN_Format11-1.png">
    <a class="" routerLinkActive="active" routerLink="">
        GOELAN
       </a>-->
    </div>
    <div id="mainListDiv" class="main_list"  [ngClass]="trigerNav ? 'show_list' : '' ">
      <ul class="">
        <li><a routerLinkActive="active" (click)="onSubmit('')">Accueil</a></li>
        <li><a routerLinkActive="active" (click)="onSubmit('orientation')">Formations de l'UHA</a></li>
        <li><a routerLinkActive="active" (click)="onSubmit('presentation')">S'orienter après le BAC</a></li>
        <li><a routerLinkActive="active" (click)="onSubmit('favoris')">Mes Favoris</a>
        </li>
        <li>
          <form  class="flexbox" [formGroup]="formVar">

          <div class="search">
            <div>
              <input  formControlName="searchBar" type="text" (keyup.enter)="getBySearch()" type="text" placeholder="Rechercher une Formation" required>
            </div>

        </div></form></li>
      </ul>
    </div>
    <span class="navTrigger" [ngClass]="trigerNav ? 'active' : '' " (click)="trigerNav = !trigerNav">
                <i></i>
                <i></i>
                <i></i>
            </span>
  </div>
</nav>


<div class=" baisser container ">
  <div class="row">
    <div class=" col-lg-4 mx-auto" style="background:  radial-gradient(#13cddc, #004dff); ;
  border-radius: 25px;padding-top: 15px ; box-shadow: 12px 12px 2px 1px rgba(0, 0, 255, .2);">

      <h1 style="text-align: center">Connectez vous !</h1>
  <form class="form-group" [formGroup]="loginForm">
    <input type="email" class="form-control" formControlName="email" placeholder="Votre Email">
    <br>
    <br>
    <input type="password" class="form-control" formControlName="password" placeholder="Votre mot de passe">
    <br>
    <br>
    <button class="btn btn-success col-lg-12" (click)="login()">Validez</button>
    <h3 style="text-align: center; cursor: pointer;" (click)="redirectCreateUser()">Pas de compte ?</h3>
  </form>
</div>
</div>
</div>

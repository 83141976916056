import { Component, OnInit } from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {HttpClient} from "@angular/common/http";
import {ApiService} from "../../../../service/api.service";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-add-competence',
  templateUrl: './add-competence.component.html',
  styleUrls: ['./add-competence.component.css']
})
export class AddCompetenceComponent implements OnInit {
  addCompetence: FormGroup;
  name;
  type;
  profil;

  profilList = [
    {profilName: 'realiste'},
    {profilName: 'social'},
    {profilName: 'artistique'},
    {profilName: 'investiguateur'},
    {profilName: 'entreprenant'},
    {profilName: 'conventionnel'}
  ];

  constructor(private fb: FormBuilder,
              private http: HttpClient,
              private api: ApiService,
              private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.addCompetence = this.fb.group({
      name: ['', [Validators.required]],
      type: ['', [Validators.required]],
      profil: ['', [Validators.required]]
    });
  }

  submitForm() {
    this.name = this.addCompetence.value.name;
    this.type = this.addCompetence.value.type;
    const values = this.addCompetence.value.profil.toString();

    if (this.name && this.type && values === null) {
      console.warn('les valeurs entrées sont vide');
    } else {
      this.api.postCompetence(this.name, this.type, values).toPromise().then((data) => {
        console.log(data);
        this.snackBar.open('la compétence : ' + this.name + ' a bien été ajoutée');
      }).catch((err) => {
        console.log(err);
      });
    }
  }

}

import {Component, Input, OnInit} from '@angular/core';
import {ApiService, FormationInterface} from '../../../service/api.service';
import {CookieService} from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-commentaire',
  templateUrl: './commentaire.component.html',
  styleUrls: ['./commentaire.component.css']
})
export class CommentaireComponent {
  num: any = 0;

  constructor(
    private _snackBar: MatSnackBar , private router: Router,private api: ApiService ,  private cookieService: CookieService ) { }
    form = new FormGroup({
    name: new FormControl('' )
  });


  submit() {

    if ( this.form.value.name === "" || this.form.value.name === null){
   }
    else {
      if(this.form.value.name === "mode:rose"){
      console.log('rose')
      this.cookieService.set('mode' , 'pink')
    }
      this.api.postCom('[COMMENTAIRE] : ' + this.form.value.name).toPromise().then()
      this._snackBar.open(
        "Merci pour votre commentaire !",
        "Fermer",
        {
          duration: 3000,
          horizontalPosition: "right"
        }
      )
      this.form.reset();
    }
}

  LienQuestion() {
    window.open('https://framaforms.org/questionnaire-utilisateur-goelan-1631606734');

  }
}

import { Component, Injectable, OnInit, Input } from '@angular/core';
import {ApiService, FormationInterface} from 'src/service/api.service';
import { ActivatedRoute } from '@angular/router';
Injectable()

@Component({
  selector: 'app-detaille',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css']
})

 export class DetailComponent implements OnInit {
  data: FormationInterface ;
  id: string;
  constructor(private api: ApiService, private route: ActivatedRoute){
  }

  ngOnInit(): void{
    this.id = this.route.snapshot.paramMap.get('id');
    this.getById(this.id);

  }

  async getById(id: string ): Promise <any>{
    await this.api.getFormationById(id).toPromise().then(
      (formation) => {
        this.data = formation;
    });
  }

}

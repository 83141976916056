import { DetailComponent } from './component/detail/detail.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './component/home/dialog-content-example-dialog.component';
import { SearchComponent } from './component/search/search.component';
import { QuestionComponent } from './component/question/question.component';
import { FormationComponent } from './component/formation/formation.component';
import { ProfilComponent } from './component/profil/profil.component';
import {AdminHomeComponent} from "./component/admin/admin-home/admin-home.component";
import {AddFormationComponent} from "./component/admin/add-formation/add-formation.component";
import {AddCompetenceComponent} from "./component/admin/add-competence/add-competence.component";
import {FavoriteComponent} from './component/favorite/favorite.component';
import {AuthGuardService} from "../service/auth-guard.service";
import {LoginComponent} from "./component/admin/login/login.component";
import {AddUserComponent} from './component/admin/add-user/add-user.component';
import {MyprofilComponent} from './component/myprofil/myprofil.component';
import {PresentationComponent} from './component/presentation/presentation.component';
import {CommentaireComponent} from './component/commentaire/commentaire.component';
import {orientationComponent} from './component/orientation/orientation.component';
import {testFrontComponent} from './component/testfront/testfront.component';


const routes: Routes = [
  { path: 'home',  component: HomeComponent,
    data: { animation: `Transition` } },
  { path: 'formation',  component: FormationComponent,
  },
  { path: 'formation/:text',  component: FormationComponent },
  { path: '', component: HomeComponent  },
 // { path: 'details/:id',  component: DetailComponent },
  { path: 'search',  component: SearchComponent,
    data: { animation: `Transition` } },
  { path: 'profil',  component: ProfilComponent,
  data: { animation: `Transition` } },
  { path: 'quiz',  component: QuestionComponent,
    data: { animation: `Transition` } },
  //  { path: 'admin', component: AdminHomeComponent },
  {path: 'favoris' , component: FavoriteComponent,
    data: { animation: `Transition` }},
  //{path: 'login', component: LoginComponent},
 {path: 'add-user', component: AddUserComponent},
  {path : 'presentation', component: PresentationComponent,
    data: { animation: `Transition` }},
  {path : 'orientation', component: orientationComponent,
    data: { animation: `Transition` }},
  {path : 'testfront', component: testFrontComponent,
    data: { animation: `Transition` }},
 // {path: 'myprofil' , component: MyprofilComponent}

];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    anchorScrolling: 'enabled',useHash :true,
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
